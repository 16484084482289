import {
  ADMIN_ROLES,
  MAPPER_ROLE,
  VSO_TEAM_ROLE,
  SUPER_ADMIN_ROLE,
  ONBOARDING_ROLE,
} from '../constants/roles';

export function isAdmin(userInfo) {
  return ADMIN_ROLES.includes(userInfo.role);
}
export function isMapper(userInfo) {
  return MAPPER_ROLE === userInfo.role;
}
export function isSuperAdmin(userInfo) {
  return userInfo.role === SUPER_ADMIN_ROLE;
}

export function isVSOTeamUser(userInfo) {
  return VSO_TEAM_ROLE === userInfo.role;
}

export function isOnboardingUser(userInfo) {
  return ONBOARDING_ROLE === userInfo.role;
}

export function getUserPresentation(userInfo) {
  return userInfo ? `${userInfo.id}-${userInfo.first_name}` : '';
}

export function getFullNameFromInfo(userInfo) {
  return userInfo ? `${userInfo?.firstName} ${userInfo?.lastName}` : '';
}

export function getFullNameFromUser(user) {
  return user ? `${user?.first_name} ${user?.last_name}` : '';
}
