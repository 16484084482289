import { useCallback, useState } from 'react';
import { useGridFilter } from 'ag-grid-react';
import { ResetFilterButton } from './ResetFilterButton';
import { SelectInput } from './SelectInput';
import { applyFiltersOnKeyPress } from '../../../helpers/tableFiltersHelper.js';

export default ({
  api,
  colDef,
  getValue,
  model,
  onModelChange,
  onModelModify,
  clientSide,
  operators,
}) => {
  const defaultOperator = operators ? operators[0] : null;
  const [localOperator, setLocalOperator] = useState(defaultOperator);

  const setFilterValue = useCallback(
    (type, value) => {
      const [filterValue, filterOperator] =
        type === 'value'
          ? [value, model?.operator || localOperator]
          : [model?.value, value];

      if (type === 'operator') {
        setLocalOperator(value);
      }

      const newModel = filterValue
        ? {
            value: filterValue,
            operator: filterOperator,
            operatorLabel: filterOperator.label.toLowerCase(),
            type: 'number',
            label: colDef.headerName,
          }
        : null;

      if (clientSide) {
        onModelChange(newModel);
      } else {
        // We are using setColumnFilterModel for server side table, to avoid instant filter apply caused by onModelChange
        api.setColumnFilterModel(colDef.field, newModel);
        onModelModify();
      }
    },
    [model, localOperator]
  );

  if (clientSide) {
    const doesFilterPass = useCallback(
      ({ node }) => {
        const value = getValue(node);
        const filterValue = Number(model?.value);
        switch (model?.operator.value) {
          case 'eq':
            return value === filterValue;
          case 'lt':
            return value < filterValue;
          case 'lte':
            return value <= filterValue;
          case 'gt':
            return value > filterValue;
          case 'gte':
            return value >= filterValue;
          case 'not':
            return value !== filterValue;
          default:
            return false;
        }
      },
      [model]
    );

    // Register filter callbacks with the grid, doesFilterPass is mandatory for client side row model type
    useGridFilter({ doesFilterPass });
  }

  const resetFilter = useCallback(() => {
    setFilterValue('value', null);
    setLocalOperator(defaultOperator);
  }, []);

  return (
    <div
      id="table-number-filter"
      onKeyDown={({ key }) => !clientSide && applyFiltersOnKeyPress(key, api)}
    >
      <ResetFilterButton onReset={resetFilter} />
      <SelectInput
        type="number"
        options={operators}
        selectValue={localOperator}
        onSelectChange={(value) => setFilterValue('operator', value)}
        inputValue={model?.value}
        onInputChange={(value) => setFilterValue('value', value)}
      />
    </div>
  );
};
