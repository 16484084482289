import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';
import {
  loadUserDetails,
  logout,
  refreshTokens,
} from '../features/user/userActions';
import { LANDING_PAGE } from '../constants/constants';
import { tokenExpired } from '../utils/helper';

const RouteRedirect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    token,
    userInfo,
    tokenExpiry,
    refreshTokenExpiry,
    refreshTokenInProgress,
  } = useSelector((state) => state.user);

  useEffect(() => {
    if (token && userInfo) {
      navigate(LANDING_PAGE[userInfo.role]);
    } else if (tokenExpired(tokenExpiry)) {
      if (tokenExpired(refreshTokenExpiry) || !refreshTokenExpiry) {
        dispatch(logout());
      } else {
        dispatch(refreshTokens());
      }
    } else if (token) {
      dispatch(loadUserDetails());
    }
  }, [navigate, token, userInfo]);

  if (token || refreshTokenInProgress) {
    //don't try to render component - that would trigger new requests and token is not ready
    return null;
  }
  return <Outlet />;
};

export default RouteRedirect;
