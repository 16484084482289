import { createPortal } from 'react-dom';
import isEqual from 'lodash/isEqual';
import { applyFilters } from '../../helpers/tableFiltersHelper';
import ColoredButton from '../common/ColoredButton';
import arrow from '../../assets/icons/arorw_right.svg';
import OutlineIconButton from '../common/OutlineIconButton.jsx';
import clearSortIcon from '../../assets/icons/clear_sort.svg';
import clearFiltersIcon from '../../assets/icons/clear_filters.svg';
import { defaultJobsStatusFilter } from '../../constants/jobConstants.js';

export const renderOverlayMessage = ({
  rowCount,
  filterActive,
  isShowAllFilters,
}) => {
  let overlayMessage;
  if (rowCount === 0) {
    overlayMessage = 'No data found for applied filters';
  } else if (!filterActive) {
    overlayMessage = 'No filters applied';
  } else if (!isShowAllFilters && (!rowCount || rowCount < 0))
    overlayMessage = 'At least one mandatory filter has to be selected';

  return createPortal(
    <div className="absolute w-96 text-center top-[30%] left-[50%] -ml-48 text-base text-gray-400">
      {overlayMessage}
    </div>,
    document.querySelector('.ag-center-cols-viewport')
  );
};

export const renderMappingsPageFilterActions = (
  gridApi,
  isRequest,
  isDataRendered,
  resetFilters,
  setShowLeavePageConfirmationDialog,
  defaultFilterModel,
  isShowAllFilters = true,
  onSortReset,
  isClearSortVisible
) => {
  return (
    <div className="flex justify-center order-first py-2">
      <OutlineIconButton
        tooltip="Clear Sort"
        outlineColor="border-gray-vs"
        bgClass="bg-white"
        padding=""
        icon={clearSortIcon}
        widthClass="w-[35px]"
        disabled={!isClearSortVisible}
        onClick={onSortReset}
      />
      <OutlineIconButton
        tooltip="Clear Filters"
        outlineColor="border-gray-vs"
        bgClass="bg-white"
        padding=""
        margin=""
        icon={clearFiltersIcon}
        widthClass="w-[35px]"
        disabled={isEqual(gridApi.getFilterModel(), defaultFilterModel)}
        onClick={() =>
          resetFilters(gridApi, defaultFilterModel).then(() =>
            setShowLeavePageConfirmationDialog(false)
          )
        }
      />
      <ColoredButton
        onClick={() => applyFilters(gridApi)}
        text="Apply Filters"
        disabled={isRequest || isDataRendered || !isShowAllFilters}
        fillColorClass="bg-purple-vs"
        hoverColorClass="hover:bg-purple-vs-hover"
        widthClass="w-[164px]"
        border="border border-solid border-purple-vs outline-none"
      />
    </div>
  );
};

export const renderJobMappingsPageFilterActions = (
  gridApi,
  isRequest,
  isDataRendered,
  resetFilters,
  setShowLeavePageConfirmationDialog,
  defaultFilterModel,
  onSortReset,
  isClearSortVisible
) => {
  return (
    <div className="flex justify-center order-first py-2">
      <OutlineIconButton
        text="Clear Sort"
        tooltip="Clear Sort"
        outlineColor="border-gray-vs"
        bgClass="bg-white"
        padding="px-2 py-1"
        icon={clearSortIcon}
        widthClass="w-[115px]"
        heightClass="h-[30px]"
        disabled={!isClearSortVisible}
        onClick={onSortReset}
      />
      <OutlineIconButton
        text="Clear Filters"
        tooltip="Clear Filters"
        outlineColor="border-gray-vs"
        bgClass="bg-white"
        padding="px-2 py-1"
        icon={clearFiltersIcon}
        widthClass="w-[115px]"
        heightClass="h-[30px]"
        disabled={isEqual(gridApi.getFilterModel(), defaultFilterModel)}
        onClick={() =>
          resetFilters(gridApi, defaultFilterModel).then(() =>
            setShowLeavePageConfirmationDialog(false)
          )
        }
      />
    </div>
  );
};

export const renderJobPageFilterActions = (
  gridApi,
  isRequest,
  isDataRendered,
  resetFilters,
  resetQuickFilter,
  onSortReset,
  isClearSortVisible
) => {
  return (
    <div className="flex justify-center order-first py-2">
      <OutlineIconButton
        text="Clear Sort"
        outlineColor="border-gray-vs"
        bgClass="bg-white"
        padding="px-2 py-1"
        icon={clearSortIcon}
        widthClass="w-[115px]"
        heightClass="h-[30px]"
        disabled={!isClearSortVisible}
        onClick={onSortReset}
        tooltip="Clear Sort"
      />
      <OutlineIconButton
        text="Clear Filters"
        tooltip="Clear Filters"
        outlineColor="border-gray-vs"
        bgClass="bg-white"
        padding="px-2 py-1"
        icon={clearFiltersIcon}
        widthClass="w-[115px]"
        heightClass="h-[30px]"
        disabled={isEqual(gridApi.getFilterModel(), {
          status: defaultJobsStatusFilter,
        })}
        onClick={() => resetFilters(gridApi).then(() => resetQuickFilter())}
      />
    </div>
  );
};

export const ArrowDown = () => {
  return (
    <img
      src={arrow}
      width="12"
      height="12"
      alt=""
      style={{ transform: 'rotate(90deg)', marginLeft: '10px' }}
    />
  );
};
