export default function UserClaimedInfo({ user }) {
  if (!user) return null;
  return (
    <div className="flex items-center text-sm">
      <div className="group relative">
        <div className="ml-4 text-sky-blue font-extrabold">{user.value}</div>
        <div className="status-tooltip">
          <div className="status-tooltip-arrow" />
          <div className="font-semibold">{`Claimed by ${user.tooltip}`}</div>
        </div>
      </div>
    </div>
  );
}
