import { useCallback, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { ResetFilterButton } from './ResetFilterButton';
import { applyFiltersOnKeyPress } from '../../../helpers/tableFiltersHelper';

export default ({ api, colDef, model, onModelModify }) => {
  const [filterValues, setFilterValues] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // We are using setColumnFilterModel for server side table, to avoid instant filter apply caused by onModelChange
  useEffect(() => {
    const newModel = filterValues?.length
      ? {
          values: filterValues.map((fV) => fV.value),
          type: 'creatable',
          label: colDef.headerName,
        }
      : null;

    api.setColumnFilterModel(colDef.field, newModel);
    onModelModify();
  }, [filterValues]);

  // handle global Clear Filters
  useEffect(() => {
    if (!model) {
      resetFilter();
    }
  }, [model]);

  const resetFilter = useCallback(() => {
    setFilterValues([]);
  }, []);

  const createUniqueValuesFromString = (input) => {
    const existingValues = filterValues.map((option) => option.value);
    return input
      .split(/[\s,]+/) // Split by space or comma
      .filter((value) => value && !existingValues.includes(value)) // Remove empty strings and duplicates
      .map((value) => ({ label: value, value }));
  };

  const handleInputChange = (newValue, { action }) => {
    if (action === 'input-change') {
      if (newValue.includes(' ') || newValue.includes(',')) {
        const newValues = createUniqueValuesFromString(newValue);
        setFilterValues((prev) => [...prev, ...newValues]);
        setInputValue('');
      } else {
        setInputValue(newValue);
      }
    }
  };

  const handleChange = (newValues) => {
    setFilterValues(newValues || []);
    setInputValue('');
  };

  return (
    <div
      id="table-radio-filter"
      onKeyDown={({ key }) => applyFiltersOnKeyPress(key, api)}
    >
      <ResetFilterButton onReset={resetFilter} />
      <div className="w-full p-2.5">
        <CreatableSelect
          isMulti
          value={filterValues}
          inputValue={inputValue}
          onChange={handleChange}
          onInputChange={handleInputChange}
          isClearable={false}
          noOptionsMessage={() => null}
        />
      </div>
    </div>
  );
};
