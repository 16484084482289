import { useCallback } from 'react';
import { useGridFilter } from 'ag-grid-react';
import { ResetFilterButton } from './ResetFilterButton';
import { applyFiltersOnKeyPress } from '../../../helpers/tableFiltersHelper.js';

export default ({
  api,
  colDef,
  getValue,
  model,
  onModelChange,
  onModelModify,
  clientSide,
}) => {
  const setFilterValue = useCallback((filterValue) => {
    const newModel = filterValue
      ? {
          value: filterValue,
          type: 'radio',
          label: colDef.headerName,
        }
      : null;

    if (clientSide) {
      onModelChange(newModel);
    } else {
      // We are using setColumnFilterModel for server side table, to avoid instant filter apply caused by onModelChange
      api.setColumnFilterModel(colDef.field, newModel);
      onModelModify();
    }
  }, []);

  if (clientSide) {
    const doesFilterPass = useCallback(
      ({ node }) => {
        const value = getValue(node);
        const filterValue = model?.value === 'Yes';

        return value === filterValue;
      },
      [model?.value]
    );

    // Register filter callbacks with the grid, doesFilterPass is mandatory for client side row model type
    useGridFilter({ doesFilterPass });
  }

  const resetFilter = useCallback(() => {
    setFilterValue(null);
  }, []);

  const Radio = ({ value }) => {
    return (
      <div className="flex-auto">
        <input
          type="radio"
          value={value}
          checked={model?.value === value}
          onChange={(e) => setFilterValue(e.target.value)}
        />
        <span className="ml-2">{value}</span>
      </div>
    );
  };

  return (
    <div
      id="table-radio-filter"
      onKeyDown={({ key }) => !clientSide && applyFiltersOnKeyPress(key, api)}
    >
      <ResetFilterButton onReset={resetFilter} />
      <div className="flex p-2.5">
        <Radio value="Yes" />
        <Radio value="No" />
      </div>
    </div>
  );
};
