import moment from 'moment/moment';
import {
  INVOICABLE_JOB_STATUSES,
  JOB_STATUS_AVAILABLE,
  JOB_STATUS_CREATED,
  JOB_STATUS_IN_PROGRESS,
  JOB_STATUS_MAPPING_COMPLETE,
  JOB_STATUSES,
  JOB_TYPES,
  JOBS_HISTORY_CSV_EXPORT_COLUMNS,
  mappableJobStatuses,
  viewableJobStatuses,
} from '../constants/jobConstants';
import {
  isAdmin,
  isMapper,
  isOnboardingUser,
  isSuperAdmin,
} from './userHelper';

export function generateMappingJobTitle(mappingJobDetails) {
  let title = `Job Mappings - #${mappingJobDetails.id}`;
  if (isClinicJob(mappingJobDetails)) {
    title += ` (Clinic ${mappingJobDetails.practice_id} - ${mappingJobDetails.practice_name} - ${mappingJobDetails.practice_city}, ${mappingJobDetails.practice_state})`;
  } else if (
    isCustomJob(mappingJobDetails) &&
    mappingJobDetails.project_tracking
  ) {
    title += ' (' + mappingJobDetails.project_tracking + ')';
  }
  return title;
}

export function jobBelongsToUser(jobDetails, userId) {
  return jobDetails.claimed_by && jobDetails.claimed_by === userId;
}

export function jobActiveBelongsToUser(jobDetails, userId) {
  return (
    jobBelongsToUser(jobDetails, userId) &&
    mappableJobStatuses.includes(jobDetails.status)
  );
}

export function jobMappingsAllowedForUser(jobDetails, userInfo) {
  return (
    (jobBelongsToUser(jobDetails, userInfo.id) &&
      mappableJobStatuses.includes(jobDetails.status)) ||
    (isAdmin(userInfo) && viewableJobStatuses.includes(jobDetails.status))
  );
}

export function jobMappingsEditableByUser(jobDetails, userInfo) {
  return (
    mappableJobStatuses.includes(jobDetails.status) &&
    jobDetails.claimed_by &&
    ((isAdmin(userInfo) && !isOnboardingUser(userInfo)) ||
      jobDetails.claimed_by === userInfo.id)
  );
}

export function isClinicJob(mappingJobDetails) {
  return mappingJobDetails.job_type === JOB_TYPES.clinic.value;
}

export function isCustomJob(mappingJobDetails) {
  return mappingJobDetails.job_type === JOB_TYPES.custom.value;
}

export function clinicDetailsAllowedForUser(mappingJobDetails, userInfo) {
  return (
    isClinicJob(mappingJobDetails) &&
    (isAdmin(userInfo) || jobBelongsToUser(mappingJobDetails, userInfo.id))
  );
}

export function canClaimJob(jobDetails, userInfo) {
  return (
    jobDetails.status === JOB_STATUS_AVAILABLE &&
    (isAdmin(userInfo) || isMapper(userInfo)) &&
    !isOnboardingUser(userInfo) &&
    moment.utc(Date.now()).format('YYYY-MM-DD') >= jobDetails.available_at
  );
}

export function canMarkJobMappingComplete(jobDetails, userInfo) {
  return (
    jobDetails.claimed_by &&
    jobDetails.status === JOB_STATUS_IN_PROGRESS &&
    (jobDetails.claimed_by === userInfo.id || isAdmin(userInfo)) &&
    !isOnboardingUser(userInfo)
  );
}

export function canMarkJobCompleted(jobDetails, userInfo) {
  return isAdmin(userInfo) && jobDetails.status === JOB_STATUS_MAPPING_COMPLETE;
}

export function canMarkJobAsInvoiced(jobDetails, userInfo) {
  return (
    isSuperAdmin(userInfo) &&
    !jobDetails.invoiced &&
    INVOICABLE_JOB_STATUSES.includes(jobDetails.status)
  );
}

export function canDeleteJob(jobDetails, userInfo) {
  return (
    (isAdmin(userInfo) && !isOnboardingUser(userInfo)) ||
    (isOnboardingUser(userInfo) &&
      [JOB_STATUS_CREATED, JOB_STATUS_AVAILABLE].includes(jobDetails.status))
  );
}

export function canEditJobDetails(userInfo) {
  return isAdmin(userInfo);
}

export const downloadJobsCSV = (gridApi, isJobsPage) => {
  const getColumnKeys = () => {
    const allColumns = gridApi.getAllDisplayedColumns();
    return isJobsPage
      ? allColumns
          .filter((col) => col.getColId() !== 'action')
          .map((col) => col.getColId())
      : JOBS_HISTORY_CSV_EXPORT_COLUMNS;
  };

  const processCell = (params) => {
    switch (params.column.colId) {
      case 'id':
        return `#${params.value}`;
      case 'status':
        return JOB_STATUSES[params.value];
      case 'claimed_by_formatted':
        return params.value?.label;
      default:
        return params.formatValue(params.value);
    }
  };

  gridApi.exportDataAsCsv({
    fileName: `jobs-data-${moment(Date.now()).format(
      'YYYY-MM-DD_hh.mm.ss'
    )}.csv`,
    columnKeys: getColumnKeys(),
    processCellCallback: processCell,
  });
};

export function canUnhideJobFromMapper(jobDetails, userInfo) {
  return (
    isAdmin(userInfo) &&
    jobDetails?.status === JOB_STATUS_IN_PROGRESS &&
    jobDetails?.hidden_from_mapper
  );
}
