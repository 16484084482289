import { memo, useState } from 'react';
import { usePopper } from 'react-popper';

const OutlineIconButton = memo(function OutlineIconButton({
  outlineColor = 'border-gray',
  widthClass = 'w-[120px]',
  heightClass = '',
  bgClass = '',
  onClick,
  text,
  uppercase,
  disabled,
  icon,
  padding = 'px-3 py-1',
  margin = 'mx-1',
  tooltip,
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [buttonRef, setButtonRef] = useState(null); // Ref for the button
  const [tooltipRef, setTooltipRef] = useState(null); // Ref for the tooltip

  // Use Popper to calculate tooltip position
  const { styles, attributes } = usePopper(buttonRef, tooltipRef, {
    placement: 'bottom', // Tooltip appears below the button by default
    modifiers: [
      { name: 'offset', options: { offset: [0, 10] } }, // 10px space below
    ],
  });

  return (
    <>
      <button
        ref={setButtonRef}
        className={`text-13 rounded hover:bg-gray-300 focus:outline-none font-normal
          ${padding}
          ${margin}
          ${outlineColor}
          ${uppercase ? 'uppercase' : ''}
          ${widthClass}
          ${heightClass}
          ${disabled ? 'bg-gray-300 text-gray-350' : 'border border-solid'}
          ${bgClass}`}
        type="button"
        onClick={onClick}
        disabled={disabled ? disabled : false}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {icon && (
          <img
            className="inline"
            width="16"
            height="16"
            src={icon}
            alt="menu-close"
          />
        )}
        {text && <span className="ml-2">{text}</span>}
      </button>
      {showTooltip && tooltip && (
        <div
          ref={setTooltipRef}
          style={styles.popper}
          {...attributes.popper}
          className="popper-status-tooltip"
        >
          {tooltip}
          <div
            className="absolute w-0 h-0 border-l-8 border-r-8 border-b-8 border-transparent border-b-tooltip-black"
            style={{
              top: '-6px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          ></div>
        </div>
      )}
    </>
  );
});

export default OutlineIconButton;
