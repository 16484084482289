import { useCallback, useEffect, useState } from 'react';
import { useGridFilter } from 'ag-grid-react';
import Select from 'react-select';
import { CustomAsyncMultiSelect } from './CustomAsyncMultiSelect';
import { CustomMultiSelect } from './CustomMultiSelect';
import { ResetFilterButton } from './ResetFilterButton';
import { applyFiltersOnKeyPress } from '../../../helpers/tableFiltersHelper.js';
import {
  CONTAINS_ALL_FILTER_VALUE,
  CONTAINS_ANY_FILTER_VALUE,
  CONTAINS_ONLY_FILTER_VALUE,
  DOES_NOT_CONTAIN_FILTER_VALUE,
  GROUPS_CONTAINS_ALL_FILTER_VALUE,
  GROUPS_CONTAINS_ANY_FILTER_VALUE,
  GROUPS_DOES_NOT_CONTAIN_FILTER_VALUE,
} from '../../../constants/constants';

export default ({
  api,
  colDef,
  getValue,
  model,
  onModelChange,
  onModelModify,
  clientSide,
  defaultValue,
  options,
  operators,
  async,
  inverted,
  field,
}) => {
  const defaultOperator = operators ? operators[0] : null;
  const [filterValues, setFilterValues] = useState();
  const [filterOperator, setFilterOperator] = useState(defaultOperator);

  useEffect(() => {
    if (defaultValue) {
      setFilterValues(defaultValue.values);
    }
  }, []);

  // We are using setColumnFilterModel for server side table, to avoid instant filter apply caused by onModelChange
  useEffect(() => {
    const createModel = () => {
      if (!filterValues?.length) return undefined;

      const baseModel = {
        values: filterValues,
        valueLabels: filterValues.map((v) => v.label),
        type: operators ? 'setWithOperators' : 'set',
        label: colDef.headerName,
      };

      return operators
        ? {
            ...baseModel,
            operator: filterOperator,
            operatorLabel: filterOperator.label.toLowerCase(),
          }
        : baseModel;
    };

    const newModel = createModel();

    if (clientSide) {
      onModelChange(newModel);
    } else {
      api.setColumnFilterModel(colDef.field, newModel);
      onModelModify();
    }
  }, [filterValues, filterOperator]);

  // handle global Clear Filters
  useEffect(() => {
    if (model?.values === defaultValue?.values) {
      setFilterValues(defaultValue?.values);
    }
  }, [model]);

  if (clientSide) {
    const filterPass = useCallback((value, filterOperator, filterValues) => {
      // multi value columns (code tags, groups)
      if (Array.isArray(value)) {
        if (defaultOperator) {
          switch (filterOperator.value) {
            case CONTAINS_ALL_FILTER_VALUE:
              return filterValues.every((filterValue) =>
                value.includes(filterValue.id)
              );
            case CONTAINS_ONLY_FILTER_VALUE:
              //In the case of Includes Only we are basically comparing arrays for equality without minding order.
              //We are using slice in order for sort to not change original array.
              return (
                filterValues
                  .map((v) => v.id)
                  .slice()
                  .sort()
                  .toString() === value.slice().sort().toString()
              );
            case CONTAINS_ANY_FILTER_VALUE:
              return filterValues.some((filterValue) =>
                value.includes(filterValue.id)
              );
            case DOES_NOT_CONTAIN_FILTER_VALUE:
              return filterValues.every(
                (filterValue) => !value.includes(filterValue.id)
              );
            case GROUPS_CONTAINS_ALL_FILTER_VALUE:
              return filterValues.every(
                (filterValue) =>
                  value.includes(filterValue.id) ||
                  filterValue.descendants_ids.some((id) => value.includes(id))
              );
            case GROUPS_CONTAINS_ANY_FILTER_VALUE:
              return filterValues.some(
                (filterValue) =>
                  value.includes(filterValue.id) ||
                  filterValue.descendants_ids.some((id) => value.includes(id))
              );
            case GROUPS_DOES_NOT_CONTAIN_FILTER_VALUE:
              return filterValues.every(
                (filterValue) =>
                  !value.includes(filterValue.id) &&
                  !filterValue.descendants_ids.some((id) => value.includes(id))
              );
          }
        } else {
          return filterValues.some((filterValue) =>
            value.includes(filterValue.id)
          );
        }
      } else if (typeof value === 'string') {
        // one value strings (status filter and claimed at in jobs table)
        return filterValues?.some((filterValue) => value === filterValue.value);
      } else {
        // one value numbers (ids - mapped by, revenue category, etc)
        if (
          operators &&
          filterOperator.value === DOES_NOT_CONTAIN_FILTER_VALUE
        ) {
          return filterValues.every((filterValue) => filterValue.id !== value);
        } else
          return filterValues.some((filterValue) => filterValue.id === value);
      }
    }, []);

    const filterPassInverted = useCallback((value, filterValues) => {
      //this is only used in job mappings table.
      //2nd condition is for the case we filter by the value to which we previously mapped some code, since after editing value becomes string
      if (Array.isArray(value)) {
        return filterValues.every(
          (filterValue) => !value.includes(filterValue.id)
        );
      } else if (typeof value === 'string') {
        let namesArray = value.split(', ');
        return filterValues.every(
          (filterValue) => !namesArray.includes(filterValue.value)
        );
      } else
        return filterValues.every((filterValue) => filterValue.id !== value);
    }, []);

    const doesFilterPass = useCallback(
      ({ node }) => {
        const value = field ? node.data[field] : getValue(node);
        return inverted
          ? filterPassInverted(value, model?.values)
          : filterPass(value, model?.operator, model?.values);
      },
      [model]
    );

    // Register filter callbacks with the grid, doesFilterPass is mandatory for client side row model type
    useGridFilter({ doesFilterPass });
  }

  const resetFilter = useCallback(() => {
    setFilterValues(null);
    setFilterOperator(defaultOperator);
  }, []);

  const selectProps = {
    options: options,
    value: model?.values || null,
    onValueChange: setFilterValues,
  };

  return (
    <div
      id="table-set-filter"
      onKeyDown={({ key }) => !clientSide && applyFiltersOnKeyPress(key, api)}
    >
      <ResetFilterButton onReset={resetFilter} />
      {async ? (
        <CustomAsyncMultiSelect {...selectProps} />
      ) : (
        <>
          {defaultOperator && (
            <Select
              options={operators}
              value={filterOperator}
              onChange={(option) => setFilterOperator(option)}
              className="w-full p-2.5"
              menuPosition="fixed"
            />
          )}
          <CustomMultiSelect {...selectProps} />
        </>
      )}
    </div>
  );
};
