import { memo, useState } from 'react';
import { usePopper } from 'react-popper';

export const UserPresentation = memo(function UserPresentation({
  label,
  tooltip,
  offset,
}) {
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offset || [10, 5],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false, // true by default
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top'],
        },
      },
    ],
  });

  return (
    <div className="group inline whitespace-normal">
      <div ref={setReferenceElement}>{label}</div>
      <div
        id="popper"
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="user-status-tooltip"
      >
        <div id="arrow" data-popper-arrow></div>
        <div className="whitespace-normal font-semibold">{tooltip}</div>
      </div>
    </div>
  );
});
