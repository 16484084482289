import { createSlice } from '@reduxjs/toolkit';
import { SAVING_STATUS } from '../../constants/constants';
import {
  loadCodeTags,
  loadGroups,
  loadPractices,
  loadRevenueCategories,
  loadUsers,
} from './tableActions';
import {
  getUserElementsWithoutAutomapper,
  getPracticeSelectElements,
  getUserSelectElements,
  getMappingSelectElements,
  getGroupSelectElements,
} from '../../helpers/selectHelper';
import { logout, resetState } from '../user/userActions';

const initialState = {
  allCodeTags: null,
  nonCustomCodeTags: null,
  allRevenueCategories: null,
  parasiticideRevenueCategories: null,
  labRevenueCategories: null,
  dietRevenueCategories: null,
  drugRevenueCategories: null,
  practices: null,
  groups: null,
  practiceGroupRelationships: null,
  users: null,
  savedStatus: SAVING_STATUS.done,
  preLoadingError: null,
  usersWithoutAutomapper: null,
};
export const tableSlice = createSlice({
  name: 'table',
  initialState: initialState,
  reducers: {
    setSavingStatus: (state, action) => {
      state.savedStatus = action.payload;
    },
  },
  extraReducers: {
    [loadCodeTags.fulfilled]: (state, { payload }) => {
      state.allCodeTags = getMappingSelectElements(payload);
      state.nonCustomCodeTags = getMappingSelectElements(
        payload.filter((v) => !v.custom_code_tag)
      );
    },
    [loadCodeTags.rejected]: (state, { payload }) => {
      state.preLoadingError = payload;
    },
    [loadRevenueCategories.fulfilled]: (state, { payload }) => {
      state.allRevenueCategories = getMappingSelectElements(payload);
      state.parasiticideRevenueCategories = payload
        .filter((v) => v.is_parasiticide)
        .map((rc) => rc.id);
      state.dietRevenueCategories = payload
        .filter((v) => v.is_diet)
        .map((rc) => rc.id);
      state.labRevenueCategories = payload
        .filter((v) => v.is_lab)
        .map((rc) => rc.id);
      state.drugRevenueCategories = payload
        .filter((v) => v.is_drug)
        .map((rc) => rc.id);
    },
    [loadRevenueCategories.rejected]: (state, { payload }) => {
      state.preLoadingError = payload;
    },
    [loadPractices.fulfilled]: (state, { payload }) => {
      state.practices = getPracticeSelectElements(payload);
      state.practiceGroupRelationships = state.practices.reduce(
        (acc, current) => {
          acc[current.id] = current.groups;
          return acc;
        },
        {}
      );
    },
    [loadPractices.rejected]: (state, { payload }) => {
      state.preLoadingError = payload;
    },
    [loadGroups.fulfilled]: (state, { payload }) => {
      state.groups = getGroupSelectElements(payload);
    },
    [loadGroups.rejected]: (state, { payload }) => {
      state.preLoadingError = payload;
    },
    [loadUsers.fulfilled]: (state, { payload }) => {
      state.users = getUserSelectElements(payload);
      state.usersWithoutAutomapper = getUserElementsWithoutAutomapper(payload);
    },
    [loadUsers.rejected]: (state, { payload }) => {
      state.preLoadingError = payload;
    },
    [logout]: (state) => {
      state.preLoadingError = null;
      state.allCodeTags = null;
      state.nonCustomCodeTags = null;
      state.allRevenueCategories = null;
      state.parasiticideRevenueCategories = null;
      state.dietRevenueCategories = null;
      state.labRevenueCategories = null;
      state.drugRevenueCategories = null;
      state.practices = null;
      state.groups = null;
      state.practiceGroupRelationships = null;
      state.users = null;
      state.usersWithoutAutomapper = null;
    },
    [resetState]: () => initialState,
  },
});

export const { setSavingStatus } = tableSlice.actions;

export default tableSlice.reducer;
