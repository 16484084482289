import { useRef, useState } from 'react';
import ColoredButton from '../../common/ColoredButton';
import { useDispatch, useSelector } from 'react-redux';
import { loadUserDetails } from '../../../features/user/userActions';
import { updateUser } from '../../../services/userServices';
import OutlineButton from '../../common/OutlineButton';
import { parseError } from '../../../helpers/errorHelper';
import { EMAIL_PATTERN } from '../../../constants/constants';

export default function EditMyProfileForm({ onCancel }) {
  const [serverError, setServerError] = useState(null);
  const { userInfo, userId } = useSelector((state) => state.user);
  const email = useRef(null);
  const firstName = useRef(null);
  const lastName = useRef(null);
  const dispatch = useDispatch();

  const formLabelPeerClasses = `peer-placeholder-shown:translate-y-0
    peer-focus:scale-75
    peer-focus:-translate-y-3
    peer-placeholder-shown:scale-100`;

  const handleSubmit = () => {
    if (
      firstName.current.value.length === 0 ||
      lastName.current.value.length === 0
    ) {
      return;
    }
    let updateParams = {
      email: email.current.value,
      first_name: firstName.current.value,
      last_name: lastName.current.value,
    };
    updateUser(userId, updateParams)
      .then(() => {
        dispatch(loadUserDetails());
        onCancel();
      })
      .catch((error) => {
        setServerError(parseError(error).description);
      });
  };

  return (
    <>
      <div className="relative mt-3">
        <input
          defaultValue={userInfo.firstName}
          id="firstName"
          ref={firstName}
          type="text"
          className="formInput peer"
          onChange={(e) => {
            e.target.value.length === 0
              ? setServerError('First Name is required')
              : setServerError(null);
          }}
        />
        <label
          htmlFor="firstName"
          className={
            userInfo.firstName
              ? 'formLabel'
              : `formLabel ${formLabelPeerClasses}`
          }
        >
          First name
        </label>
      </div>
      <div className="relative mt-3">
        <input
          defaultValue={userInfo.lastName}
          id="lastName"
          ref={lastName}
          type="text"
          className="formInput peer"
          onChange={(e) => {
            e.target.value.length === 0
              ? setServerError('Last Name is required')
              : setServerError(null);
          }}
        />
        <label
          htmlFor="lastName"
          className={
            userInfo.lastName
              ? 'formLabel'
              : `formLabel ${formLabelPeerClasses}`
          }
        >
          Last name
        </label>
      </div>
      <div className="relative mt-3">
        <input
          defaultValue={userInfo.email}
          id="email"
          ref={email}
          type="text"
          className="formInput peer"
          onChange={(e) => {
            if (e.target.value.length === 0) {
              setServerError('Email is required');
            } else if (!e.target.value.match(EMAIL_PATTERN)) {
              setServerError('Entered value does not match email format');
            } else {
              setServerError(null);
            }
          }}
        />
        <label htmlFor="email" className="formLabel">
          Email
        </label>
      </div>
      <div className="h-4 w-full mb-4 text-center text-red-500 text-xs">
        {serverError}
      </div>
      <div className="flex-1 text-center mb-2">
        <OutlineButton
          outlineColor="border-gray"
          text="Cancel"
          widthClass="w-[120px]"
          heightClass="h-10"
          onClick={() => {
            setServerError(null);
            onCancel();
          }}
        />
        <ColoredButton
          fillColor="bg-vs-green"
          text="Save Changes"
          widthClass="w-[120px]"
          heightClass="h-10"
          onClick={() => {
            if (!serverError) handleSubmit();
          }}
        />
      </div>
    </>
  );
}
