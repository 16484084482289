import { useCallback, useEffect, useState } from 'react';
import { useGridFilter } from 'ag-grid-react';
import { ResetFilterButton } from './ResetFilterButton';
import { revenue_category_id } from '../../../constants/constants';

export default ({ colDef, model, onModelChange, parasiticideIds }) => {
  const [filterValue, setFilterValue] = useState();

  useEffect(() => {
    const newModel = filterValue
      ? {
          value: filterValue,
          type: 'radio',
          label: colDef.headerName,
        }
      : null;

    onModelChange(newModel);
  }, [filterValue]);

  // handle global Clear Filters and setting default values
  useEffect(() => {
    if (model) {
      setFilterValue(model.value);
    } else {
      resetFilter();
    }
  }, [model]);

  const doesFilterPass = useCallback(
    ({ node }) => {
      const value = node.data[revenue_category_id.field];
      const filterValue = model?.value === 'Yes';
      return filterValue
        ? parasiticideIds.includes(value)
        : !parasiticideIds.includes(value);
    },
    [model]
  );

  // Register filter callbacks with the grid, doesFilterPass is mandatory for client side row model type
  useGridFilter({ doesFilterPass });

  const resetFilter = useCallback(() => {
    setFilterValue(null);
  }, []);

  const Radio = ({ value }) => {
    return (
      <div className="flex-auto">
        <input
          type="radio"
          value={value}
          checked={filterValue === value}
          onChange={(e) => setFilterValue(e.target.value)}
        />
        <span className="ml-2">{value}</span>
      </div>
    );
  };

  return (
    <div id="table-radio-filter">
      <ResetFilterButton onReset={resetFilter} />
      <div className="flex p-2.5">
        <Radio value="Yes" />
        <Radio value="No" />
      </div>
    </div>
  );
};
