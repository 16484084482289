import { Fragment, useState, useRef, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import close from '../assets/icons/close.svg';
import menu from '../assets/icons/menu.svg';
import { MENU_OPTIONS, pageTitles } from '../constants/constants';
import { logout } from '../features/user/userActions';
import {
  generateMappingJobTitle,
  jobMappingsAllowedForUser,
} from '../helpers/jobsHelper';
import { openViewJobDetails } from '../features/modal/modalActions';
import { ErrorContext } from '../ErrorContext';
import { getFullNameFromInfo } from '../helpers/userHelper.js';

function isJobMappingPage(location) {
  return location.pathname.includes('jobmapping');
}

function detailsLoadedForCurrentJob(jobDetails, jobUrl) {
  let jobIdFromUrl = parseInt(jobUrl.split('/').reverse()[0]);
  return jobDetails && jobIdFromUrl === jobDetails.id;
}

export default function Navbar() {
  const { setErrorAlert } = useContext(ErrorContext);
  const { userInfo } = useSelector((state) => state.user);
  const { mappingJobDetails, loadingJobDetails, loadingJobDetailsError } =
    useSelector((state) => state.job);
  const [title, setTitle] = useState(null);
  const optionsList = MENU_OPTIONS[userInfo.role];
  const [isOpen, setOpen] = useState(false);
  const sidebar = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (isJobMappingPage(location)) {
      //avoid showing previously visited job title
      if (
        mappingJobDetails &&
        detailsLoadedForCurrentJob(mappingJobDetails, location.pathname)
      ) {
        let jobTitle = jobMappingsAllowedForUser(mappingJobDetails, userInfo)
          ? generateMappingJobTitle(mappingJobDetails)
          : 'Job Mappings';
        setTitle(jobTitle);
        document.title = jobTitle;
      } else {
        setTitle(null);
        document.title = '';
      }
    } else {
      const currentTitle =
        pageTitles[location.pathname.split('/', 2).join('/')];
      setTitle(currentTitle);
      document.title = currentTitle;
    }
  }, [location, mappingJobDetails]);

  const handleClickOutside = (event) => {
    if (sidebar.current && !sidebar.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const sidebarMenu = () => {
    const sidebarOptions = optionsList.map((o, i) => optionLayout(o, i));
    return (
      <div
        ref={sidebar}
        className={
          isOpen
            ? 'h-full absolute z-10 w-60 pt-2 bg-light-gray shadow-basic flex flex-col -ml-2'
            : 'hidden'
        }
      >
        <div className="flex justify-end mb-4">
          <img
            className="pr-2 pt-1 cursor-pointer"
            onClick={() => setOpen(false)}
            width="35"
            height="35"
            src={close}
            alt="menu-close"
          />
        </div>
        {sidebarOptions}
      </div>
    );
  };

  const optionLayout = (option, index) => {
    let isActive =
      location.pathname ===
      optionsList.find((o) => o.name === option.name).pathname;
    const base = 'cursor-pointer pl-2 pr-2';
    const active = 'font-normal';
    const srcUrl = new URL(`../assets/icons/${option.file}`, import.meta.url)
      .href;

    return (
      <div key={index} className="flex flex-col max-w-max mb-3">
        <div
          className="pl-2 inline-flex cursor-pointer"
          onClick={() => handleClick(option)}
        >
          <img width="25" height="20" src={srcUrl} alt={option.name} />
          <div className={isActive ? base + ' ' + active : base}>
            {option.name}
          </div>
        </div>
        <div className={isActive ? 'h-1 bg-vs-green rounded-r-sm' : 'hidden'} />
      </div>
    );
  };

  const handleClick = (option) => {
    if (option.name === 'Log out') {
      document.title = 'Mapping App - Login';
      dispatch(logout());
      return <Navigate to="/login" />;
    } else {
      setOpen(false);
      navigate(option.pathname);
    }
  };

  let showJobDetailsTitleLink =
    isJobMappingPage(location) &&
    detailsLoadedForCurrentJob(mappingJobDetails, location.pathname) &&
    !loadingJobDetails &&
    !loadingJobDetailsError;
  return (
    <div className="w-full flex min-h-[3rem] bg-bgGreen flex-grow-0 font-light text-15 pl-2 pr-4">
      {!isOpen && (
        <Fragment>
          <img
            className="cursor-pointer"
            onClick={() => setOpen(true)}
            width="35"
            height="35"
            src={menu}
            alt="menu-icon"
          />
          {showJobDetailsTitleLink && (
            <div
              className="title title-link"
              onClick={async () => {
                try {
                  await dispatch(
                    openViewJobDetails(mappingJobDetails.id)
                  ).unwrap();
                } catch (error) {
                  setErrorAlert({ parsedError: error });
                }
              }}
            >
              {title}
            </div>
          )}
          {!isJobMappingPage(location) && (
            <div className="title" id="optionTitle">
              {title}
            </div>
          )}
        </Fragment>
      )}
      {sidebarMenu()}
      <div className="flex-1 self-center text-white">
        <div
          className="cursor-pointer max-w-min whitespace-nowrap float-right hover:underline decoration-1"
          onClick={() => navigate('/myprofile')}
        >
          {getFullNameFromInfo(userInfo)}
        </div>
      </div>
    </div>
  );
}
