import { memo, useCallback } from 'react';
import { useGridCellEditor } from 'ag-grid-react';

export default memo(({ value }) => {
  const isCancelAfterEnd = useCallback(() => true, []);

  useGridCellEditor({ isCancelAfterEnd });

  return (
    <textarea
      className="pseudo-edit-textarea"
      value={value || ''}
      readOnly={true}
    />
  );
});
