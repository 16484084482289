import { Dialog, Tab } from '@headlessui/react';

export default function CreateJobModal({
  openState,
  onClose,
  clinicJobForm,
  customJobForm,
}) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <Dialog open={openState} onClose={() => onClose(false)}>
      <div className="fixed inset-0 bg-black/30" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex items-center justify-center p-4 text-center">
          <Dialog.Panel className="w-[500px] rounded-sm bg-white px-14 py-8 text-left align-middle shadow-xl flex-col">
            <Tab.Group>
              <Tab.List className="flex">
                {!!clinicJobForm && (
                  <Tab
                    data-testid="practice-job-tab"
                    className={({ selected }) =>
                      classNames(
                        'rounded-sm',
                        'tab',
                        selected
                          ? 'selected-tab'
                          : 'not-selected-tab rounded-r-none mr-[-2px]'
                      )
                    }
                  >
                    PRACTICE
                  </Tab>
                )}
                {!!customJobForm && (
                  <Tab
                    data-testid="custom-job-tab"
                    className={({ selected }) =>
                      classNames(
                        'tab',
                        selected
                          ? 'selected-tab rounded-sm'
                          : 'not-selected-tab rounded-sm rounded-l-none ml-[-2px]'
                      )
                    }
                  >
                    CUSTOM
                  </Tab>
                )}
              </Tab.List>
              <Tab.Panels className="mt-2">
                {!!clinicJobForm && <Tab.Panel>{clinicJobForm}</Tab.Panel>}
                {!!customJobForm && <Tab.Panel>{customJobForm}</Tab.Panel>}
              </Tab.Panels>
            </Tab.Group>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
