export const MAPPER_ROLE = 'mapper';
export const ADMIN_ROLE = 'admin';
export const SUPER_ADMIN_ROLE = 'super_admin';
export const VSO_TEAM_ROLE = 'vso_team';
export const ONBOARDING_ROLE = 'onboarding';

export const ALL_ROLES = [
  SUPER_ADMIN_ROLE,
  ADMIN_ROLE,
  MAPPER_ROLE,
  VSO_TEAM_ROLE,
  ONBOARDING_ROLE,
];
export const ADMIN_ROLES = [SUPER_ADMIN_ROLE, ADMIN_ROLE, ONBOARDING_ROLE];
export const JOBS_ROLES = [SUPER_ADMIN_ROLE, ADMIN_ROLE, MAPPER_ROLE];

export const ROLE_NAMES = {
  [MAPPER_ROLE]: 'Mapper',
  [ADMIN_ROLE]: 'Admin',
  [SUPER_ADMIN_ROLE]: 'Super Admin',
  [VSO_TEAM_ROLE]: 'VSO Team',
  [ONBOARDING_ROLE]: 'Onboarding',
};

export const ROLE_NAMES_LIST = [
  { id: MAPPER_ROLE, name: ROLE_NAMES[MAPPER_ROLE] },
  { id: ADMIN_ROLE, name: ROLE_NAMES[ADMIN_ROLE] },
  { id: SUPER_ADMIN_ROLE, name: ROLE_NAMES[SUPER_ADMIN_ROLE] },
  { id: VSO_TEAM_ROLE, name: ROLE_NAMES[VSO_TEAM_ROLE] },
  { id: ONBOARDING_ROLE, name: ROLE_NAMES[ONBOARDING_ROLE] },
];
