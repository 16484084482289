import { memo, useCallback, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { enterPressed } from '../utils/helper';
import { selectFilter } from '../helpers/selectHelper';
import {
  formatCTValues,
  removeBracketsAndNumbers,
} from '../helpers/tableHelper';
import { findSquareBreckets } from '../constants/constants';

let formatValues = (propOptions, propValue) => {
  let currentOptions = removeBracketsAndNumbers(propValue).split(', ');
  let values = propOptions.filter((option) =>
    currentOptions.includes(option.value)
  );
  if (values.length !== currentOptions.length) {
    currentOptions.forEach((option) => {
      if (!propOptions.find((ct) => ct.value === option)) {
        values.push({ id: option, label: option, value: option });
      }
    });
  }
  return values;
};

/*
When using closeMenuOnSelect={true} and default react-select behaviour, when user removes value with 'x'
menu is not being closed, so instead of confirming choices on enter, first value from the list is being selected
Because of that we introduced menuIsOpen and handled open status on our own:
 - Menu will open on mouse click, arrow key down, or onInputChanged (user is typing)
 - Menu will be closed when some closeMenuActions is triggered
 */
export default memo(
  ({
    value,
    onValueChange,
    stopEditing,
    column,
    eventKey,
    values,
    selectableValues,
  }) => {
    const localValue = value ? formatValues(values, value) : null;
    //handle first letter lost on typing and enter key
    const [inputValue, setInputValue] = useState(
      eventKey && eventKey.length === 1 ? eventKey : ''
    );
    const [scroll, setScroll] = useState(false);
    const refInput = useRef(null);
    const input = useRef(null);
    const [menuIsOpen, setMenuIsOpen] = useState(true);
    const closeMenuActions = ['remove-value', 'pop-value', 'select-option'];

    useEffect(() => {
      //disable scroll outside of menuList. when scroll happens, finish editing
      if (scroll) {
        stopEditing();
      }
    }, [scroll]);

    useEffect(() => {
      refInput.current.focus();
    }, []);

    useEffect(() => {
      document.addEventListener('scroll', handleScrollOutside, true);
      return () => {
        document.removeEventListener('scroll', handleScrollOutside, true);
      };
    }, []);

    const setValue = useCallback((options, actionMeta) => {
      if (closeMenuActions.includes(actionMeta.action)) {
        setMenuIsOpen(false);
      }
      setInputValue('');
      const newValue = options
        ? formatCTValues(
            values,
            options.map((v) => v.id),
            (ct) => `${ct.value}[${ct.id}]`
          )
        : [];
      onValueChange(newValue);
    }, []);

    const handleScrollOutside = (event) => {
      if (input.current && !input.current.contains(event.target)) {
        setScroll(true);
      }
    };

    const CustomStyle = {
      control: (styles) => ({
        ...styles,
        width: column.actualWidth,
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        display: 'none',
      }),
      clearIndicator: (styles) => ({
        ...styles,
        display: 'none',
      }),
    };

    const resolveEnterKeyDownEvent = () => {
      //trigger remove-value event when clicking 'enter' when x is in focus(red)
      let removeItemButton = document.getElementsByClassName(
        'css-1ke7a9t select__multi-value__remove'
      );
      if (removeItemButton.length > 0) {
        let codeTagName =
          removeItemButton[0].parentElement.firstElementChild.innerText;
        let [optionToRemove, newValueArray] = localValue.reduce(
          (result, element) => {
            result[element.value === codeTagName ? 0 : 1].push(element);
            return result;
          },
          [[], []]
        );
        refInput.current.onChange(newValueArray, {
          action: 'remove-value',
          name: 'multiselect',
          removedValue: optionToRemove[0],
        });
      } else if (!menuIsOpen) {
        stopEditing();
      }
    };

    const onInputChange = useCallback((input) => {
      let value = findSquareBreckets.test(input)
        ? removeBracketsAndNumbers(input)
        : input;
      setMenuIsOpen(true);
      setInputValue(value);
    }, []);

    return (
      <div
        translate="no"
        ref={input}
        onKeyDownCapture={(e) => {
          if (e.code === 'ArrowDown') {
            if (!menuIsOpen) setMenuIsOpen(true);
          } else if (enterPressed(e)) {
            resolveEnterKeyDownEvent();
          }
        }}
        onMouseDownCapture={() => setMenuIsOpen(true)}
      >
        <Select
          name="multiselect"
          ref={refInput}
          defaultValue={localValue}
          isMulti
          options={selectableValues}
          className="basic-multi-select"
          classNamePrefix="select"
          onInputChange={onInputChange}
          inputValue={inputValue}
          onChange={(options, actionMeta) => setValue(options, actionMeta)}
          filterOption={selectFilter}
          styles={CustomStyle}
          backspaceRemovesValue={true}
          openMenuOnFocus
          menuPlacement="auto"
          menuIsOpen={menuIsOpen}
        />
      </div>
    );
  }
);
