import { saveAs } from 'file-saver';
import { debounce } from 'lodash';
import {
  ARROWS_KEY_CODES,
  allDigitsRgx,
  code_tags_ids,
  DELETE_KEY_CODES,
  free_doses,
  paid_doses,
  pms_code_vetsuccess_id,
  PSEUDO_EDIT_CLASS,
  pseudoEditableFields,
  revenue_category_id,
  review_status,
  UP_DOWN_KEY_CODES,
  verified,
  verification_pipeline_status_id,
  regexParseNameAndId,
  UNDO_REDO_ACTIONS,
} from '../constants/constants';
import { throwInvalidValue } from './errorHelper';
import { updateTableData } from '../services/tableServices';
import {
  addCodeTagsBatchActionClicked,
  removeCodeTagsBatchActionClicked,
  replaceCodeTagsBatchActionClicked,
  revenueCategoryBatchActionClicked,
  updateFreeDosesBatchActionClicked,
  updatePaidDosesBatchActionClicked,
  updateReviewStatusBatchActionClicked,
  updateVerificationStatusBatchActionClicked,
} from '../features/modal/modalSlice';
import {
  CLINIC_JOB_TYPE,
  UPDATE_WAITING_TIME_LIMIT,
} from '../constants/jobConstants';
import { UserPresentation } from '../components/table/UserPresentation.jsx';

export const sidebarDef = {
  toolPanels: [
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        suppressFilterSearch: true,
        suppressExpandAll: true,
        suppressSyncLayoutWithGrid: true,
      },
    },
  ],
  defaultToolPanel: 'filters',
};

//when we don't have count, ag grid will show count if data fetched count
//is smaller than page size - use same rule for csv download
export const guessRowCount = (gridApi, dataCount) => {
  if (
    gridApi.paginationGetCurrentPage() === 0 &&
    dataCount < gridApi.paginationGetPageSize()
  ) {
    return dataCount;
  } else {
    return -1;
  }
};

export const paginationNumberFormatter = (params) => {
  if (params.value === 50000) {
    return '[50.000+]';
  }
  return '[' + params.value.toLocaleString() + ']';
};

export const getValueById = (array, id) => {
  const element = array.find((e) => e.id === id);
  return element ? element.value : id;
};

//tooltip is additional info not used by select
export const getTooltipById = (array, id) => {
  const element = array.find((e) => e.id === id);
  return element ? element.tooltip : id;
};

export const getElementById = (array, id) => {
  return array.find((e) => e.id === id);
};

export const htmlDecode = (input) => {
  return new DOMParser().parseFromString(input, 'text/html').documentElement
    .textContent;
};

// Supported formats of input values for code tags are:
// Diet/ Derm[6], Diet/ Gastro[4] - comma separated list of ct_name[ct_id] - main format (fill handle, inline edit)
// 1, 2, 3 - comma separated code tag ids (can be pasted directly in cell)
// Diet/ Derm, Diet/ Gastro - comma separated list of names (can be pasted directly in cell)
const extractCodeTagIds = (inputValue, codeTags) => {
  //handle list of numbers
  if (!inputValue) {
    return [];
  }
  if (inputValue.split(', ').every((ct) => allDigitsRgx.test(ct))) {
    let ids = inputValue.split(', ').map(Number);
    return ids.map((id) => {
      const isValid = codeTags.some((ct) => ct.id === id);
      if (!isValid) {
        throwInvalidValue(id);
      }
      return id;
    });
  }
  //handle list of names or name[id] values
  //any other format will be handled here and if not supported - invalid value will be thrown
  try {
    let codeTagIds = [];
    inputValue.split(', ').forEach((ct) => {
      let parsedCodeTagName = parseNameFromStringWithId(ct);
      let parsedCodeTagId = parseIdFromStringWithId(ct);
      let codeTag = codeTags.find((code) =>
        parsedCodeTagId
          ? parsedCodeTagName === code.value && parsedCodeTagId === code.id
          : parsedCodeTagName === code.value
      );
      if (codeTag) {
        codeTagIds.push(codeTag.id);
      } else {
        throwInvalidValue(ct);
      }
    });
    return codeTagIds;
  } catch (error) {
    if (error.message === 'Invalid value') {
      throw error;
    } else throwInvalidValue(inputValue);
  }
};

const findRevenueCategoryId = (category, revenueCategories) => {
  let categoryName = parseNameFromStringWithId(category);
  let categoryId = parseIdFromStringWithId(category);
  let revenueCategory = revenueCategories.find((rc) =>
    categoryId
      ? categoryName === rc.value && categoryId === rc.id
      : categoryName === rc.value
  );
  if (revenueCategory) {
    return revenueCategory.id;
  } else {
    throwInvalidValue(category);
  }
};

export const formatRequest = (
  changedField,
  rowData,
  newValue,
  codeTags,
  revenueCategories,
  undoAction = false
) => {
  let requestData = {};
  requestData[pms_code_vetsuccess_id.field] =
    rowData[pms_code_vetsuccess_id.field];

  switch (changedField) {
    case code_tags_ids.field: {
      requestData[changedField] = extractCodeTagIds(newValue, codeTags);
      break;
    }
    case revenue_category_id.field: {
      //handling copy/paste or fill handle of empty revenue category (don't let user to set null rev category)
      //we removed logic for value types from valueSetter, since the grid can handle it all by itself
      //now we are just looking to convert it to number if it is a digit string or we can get an id from rc name
      if (
        undoAction &&
        revenue_category_id.emptyMappingValues.includes(newValue)
      ) {
        //allow setting to empty revenue category for undo action
        requestData[changedField] = null;
      } else if (!newValue) {
        requestData = null;
      } else if (newValue && allDigitsRgx.test(newValue)) {
        if (revenueCategories.map((rc) => rc.id).includes(parseInt(newValue))) {
          requestData[changedField] = parseInt(newValue);
        } else throwInvalidValue(newValue);
      } else {
        requestData[changedField] = findRevenueCategoryId(
          newValue,
          revenueCategories
        );
      }
      break;
    }
    case review_status.field: {
      if (newValue) {
        requestData[changedField] = newValue;
      } else requestData[changedField] = '';
      break;
    }
    case verified.field:
      requestData[changedField] = newValue;
      break;
    case verification_pipeline_status_id.field:
      requestData[changedField] = newValue;
      break;
    default:
      if (!newValue) {
        requestData[changedField] = 0;
      } else requestData[changedField] = newValue;
      break;
  }

  return requestData;
};

function getNodes(startRowIndex, endRowIndex, gridApi) {
  let nodes = [];
  for (let i = startRowIndex; i <= endRowIndex; i++) {
    nodes.push(gridApi.getDisplayedRowAtIndex(i));
  }
  return nodes;
}

export function isDosesColumn(column) {
  return [free_doses.field, paid_doses.field].includes(column);
}

export function isPseudoeditableColumn(column) {
  return pseudoEditableFields.includes(column);
}

function getNullValue(columnField) {
  if (isDosesColumn(columnField)) return 0;
  if (columnField === code_tags_ids.field) return [];
  return null;
}

function isEditable(column) {
  return (
    column.colDef.editable && column.colDef.cellClass !== PSEUDO_EDIT_CLASS
  );
}

const focusCell = (gridApi, rowIndex, columnField) => {
  gridApi.clearRangeSelection();
  gridApi.setFocusedCell(rowIndex, columnField, null);
  gridApi.addCellRange({
    rowStartIndex: rowIndex,
    rowEndIndex: rowIndex,
    columnEnd: columnField,
    columnStart: columnField,
  });
};

export const advanceToNextRow = (gridApi, rowIndex, column) => {
  let pageSize = gridApi.paginationGetPageSize();
  let rowPositionOnCurrentPage = (rowIndex % pageSize) + 1;
  let nextRow = getNodes(rowIndex + 1, rowIndex + 1, gridApi);
  if (nextRow[0] && pageSize > rowPositionOnCurrentPage) {
    focusCell(gridApi, rowIndex + 1, column);
  }
};

export const deleteData = (
  setIsRequest,
  gridApi,
  setErrorAlert,
  codeTags,
  updateDataServiceMethod,
  changeMappedCodeCount,
  jobId
) => {
  let range = gridApi.getCellRanges()[0];
  let startRow = range.startRow.rowIndex;
  let endRow = range.endRow.rowIndex;
  let startRowIndex = Math.min(startRow, endRow);
  let endRowIndex = Math.max(startRow, endRow);
  let singleRow = startRowIndex === endRowIndex;
  //we have to know previousValue in the case of using backspace on single doses column, in case it was already nullValue we won't advance
  let previousValueIfSingleDosesDelete = null;
  let params = {};
  let updatedColumns = [];
  range.columns.forEach((column) => {
    let columnField = column.colId;
    if (singleRow && range.columns.length === 1 && isDosesColumn(columnField)) {
      previousValueIfSingleDosesDelete = getNodes(
        startRowIndex,
        endRowIndex,
        gridApi
      )[0].data[columnField];
    }
    if (
      isEditable(column) &&
      ![verified.field, revenue_category_id.field].includes(columnField)
    ) {
      params[columnField] = getNullValue(columnField);
      updatedColumns.push(columnField);
    }
  });
  if (Object.keys(params).length > 0) {
    if (endRowIndex - startRowIndex > 0) {
      params.batch_action = 'batch update';
    }
    let nodes = getNodes(startRowIndex, endRowIndex, gridApi);
    params[pms_code_vetsuccess_id.field] = nodes
      .filter((node) =>
        range.columns
          .filter(
            (column) =>
              ![verified.field, revenue_category_id.field].includes(
                column.colId
              )
          )
          .some((column) =>
            cellValueChanged(
              node.data[column.colId],
              getNullValue(column.colId)
            )
          )
      )
      .map((node) => node.id);
    if (params[pms_code_vetsuccess_id.field].length) {
      let timerId = setTimeout(function () {
        setIsRequest(true);
        gridApi.setGridOption('loading', true);
      }, UPDATE_WAITING_TIME_LIMIT);
      updateDataServiceMethod(params, jobId)
        .then((updatedResources) => {
          updateTableData(nodes, updatedResources, codeTags, updatedColumns);
          //we should advance to next row if user hit delete on one doses cell
          if (previousValueIfSingleDosesDelete) {
            advanceToNextRow(gridApi, startRowIndex, updatedColumns[0]);
          }
        })
        .catch((error) => {
          setErrorAlert({ error: error });
        })
        .finally(() => {
          if (changeMappedCodeCount) changeMappedCodeCount();
          clearLongRequest(timerId, setIsRequest, gridApi);
        });
    }
  }
};

export const formatCTValues = (allCodeTags, codeTagIds, formatFn) => {
  const allCodeTagIds = allCodeTags.map((ct) => ct.id);
  let existingCodeTagNames = allCodeTags
    .filter((ct) => codeTagIds.includes(ct.id))
    .map((ct) => formatFn(ct));
  let nonExistingIds = codeTagIds.filter(
    (ctid) => !allCodeTagIds.includes(ctid)
  );
  return existingCodeTagNames.concat(nonExistingIds).join(', ');
};

export const saveCSVFile = (response) => {
  const filename = response.headers['content-disposition']
    .split(';')
    .find((n) => n.includes('filename='))
    .replace('filename=', '')
    .trim()
    .replaceAll('"', '');
  saveAs(
    new Blob([response.data], { type: 'text/cdv;charset=utf-8' }),
    filename
  );
};

const saveFillHandleChanges = debounce(
  (
    setIsRequest,
    fillOperationParams,
    fillHandleDataRef,
    setErrorAlert,
    allCodeTags,
    updateDataServiceMethod,
    changeMappedCodeCount,
    jobId = null
  ) => {
    const fillHandleRequests = fillHandleDataRef.current.requests;
    const fillHandleRequest = {};
    Object.assign(fillHandleRequest, ...fillHandleRequests);
    const ids = new Set();
    fillHandleRequests.forEach((req) => {
      ids.add(req[pms_code_vetsuccess_id.field]);
    });
    const columns = new Set();
    fillHandleDataRef.current.rows.forEach((row) => {
      columns.add(row.column);
    });
    fillHandleRequest[pms_code_vetsuccess_id.field] = [...ids];
    let timerId = setTimeout(function () {
      setIsRequest(true);
      fillOperationParams.api.setGridOption('loading', true);
    }, UPDATE_WAITING_TIME_LIMIT);
    updateDataServiceMethod(fillHandleRequest, jobId)
      .then((response) => {
        fillHandleDataRef.current.rows.forEach(({ node }) => {
          updateTableData([node], response, allCodeTags, [...columns]);
        });
      })
      .catch((err) => {
        setErrorAlert({ error: err });
        fillHandleDataRef.current.rows.forEach(({ node, oldValue, column }) => {
          node.setDataValue(column, oldValue);
        });
      })
      .finally(() => {
        fillHandleDataRef.current = {
          requests: [],
          rows: [],
        };
        if (changeMappedCodeCount) changeMappedCodeCount();
        clearLongRequest(timerId, setIsRequest, fillOperationParams.api);
      });
  },
  100
);

export const onCellEditingStopped = (event, updateCallback, setErrorAlert) => {
  try {
    if (!isPseudoEditableField(event)) {
      if (
        event.valueChanged &&
        cellValueChanged(event.oldValue, event.newValue)
      ) {
        let column = event.column.colId;
        updateCallback(
          column,
          event.data,
          event.newValue,
          event.node,
          event.oldValue
        );
      }
    }
  } catch (error) {
    setErrorAlert({ error: error });
    event.node.setDataValue(event.column.colId, event.oldValue);
  }
};

const isPseudoEditableField = (cellClass) => {
  return cellClass && cellClass === PSEUDO_EDIT_CLASS;
};

function isEditInProgress(gridApi) {
  return (
    gridApi.getEditingCells().length > 0 &&
    !pseudoEditableFields.includes(gridApi.getEditingCells()[0].column.colId)
  );
}

export const onCellMouseOver = (
  params,
  previousMouseOverColumnRef,
  cellFocusedByKeyboardNavigationRef
) => {
  //ignore selecting a cell if:
  //user clicked a button (doing range selection or fill handle)
  //editing in progress (hover will be registered and editing will be stopped) - another way?
  //column is not editable or it it one of pseudo editable cells (no need to make selection of cell)

  //we have to detect keyboard navigation and prevent focus when page scrolls and this event triggers
  //we are setting cellFocusedByKeyboardNavigation in the onCellKeyDown callback
  //if the mouse is not moving (across columns) it is a true condition
  let isCursorInSameColumn =
    previousMouseOverColumnRef.current === params.colDef.field;
  previousMouseOverColumnRef.current = params.colDef.field;
  let eventTriggeredBecauseOfGridScroll =
    cellFocusedByKeyboardNavigationRef.current && isCursorInSameColumn;
  if (
    eventTriggeredBecauseOfGridScroll ||
    params.event.buttons === 1 ||
    isEditInProgress(params.api) ||
    !params.colDef.editable ||
    isPseudoEditableField(params)
  ) {
    return;
  }
  focusCell(params.api, params.node.rowIndex, params.colDef.field);
  //once we allow mouse selection on hover, we discard last saved focused cell value
  //it will again be an object once keyboard navigation starts again
  cellFocusedByKeyboardNavigationRef.current = false;
};

export const clearLongRequest = (timerId, setIsRequest, gridApi) => {
  clearTimeout(timerId);
  setIsRequest(false);
  gridApi.setGridOption('loading', false);
};

export const fillOperation = (
  setIsRequest,
  fillOperationParams,
  fillHandleDataRef,
  allCodeTags,
  allRevenueCategories,
  updateDataServiceMethod,
  setErrorAlert,
  changeMappedCodeCount,
  jobId
) => {
  if (!fillOperationParams.column.colDef.editable) {
    return;
  }
  if (
    fillOperationParams.column.colId === revenue_category_id.field &&
    !fillOperationParams.initialValues[0]
  ) {
    // if fill handle is done over rc column with null as initial value, we just keep current cellValue and don't push anything to fillHandleData
    return fillOperationParams.currentCellValue;
  }

  let column = fillOperationParams.column.colId;
  let initialValue = fillOperationParams.initialValues[0];
  // We handle an edge case where the code(s) have only the review_status changed,
  // but other code(s) in the fill operation may have some other fields changed as well.
  // In this scenario, the code(s) with only the review_status changed will also have
  // mapped_at changed, which is not desired.
  if (!cellValueChanged(fillOperationParams.currentCellValue, initialValue)) {
    return fillOperationParams.currentCellValue;
  }
  try {
    let formattedRequest = formatRequest(
      column,
      fillOperationParams.rowNode.data,
      initialValue,
      allCodeTags,
      allRevenueCategories
    );

    if (formattedRequest) {
      fillHandleDataRef.current.requests.push(formattedRequest);
      fillHandleDataRef.current.rows.push({
        node: fillOperationParams.rowNode,
        oldValue: fillOperationParams.currentCellValue,
        column: column,
      });
    }

    if (fillHandleDataRef.current.requests.length) {
      saveFillHandleChanges(
        setIsRequest,
        fillOperationParams,
        fillHandleDataRef,
        setErrorAlert,
        allCodeTags,
        updateDataServiceMethod,
        changeMappedCodeCount,
        jobId
      );
    }
  } catch (error) {
    setErrorAlert({ error: error });
    return fillOperationParams.currentCellValue;
  }

  return initialValue;
};

export const getContextMenuItems = (
  e,
  dispatch,
  getBatchUpdateHandler,
  updateDataServiceMethod,
  jobType
) => {
  if (!e.node) return null;
  const contextMenuItems = [
    {
      name: 'Verify Selected',
      shortcut: 'Ctrl + 1',
      action: () => {
        getBatchUpdateHandler(
          updateDataServiceMethod,
          [verified.field],
          false
        )({
          batch_action: 'batch verified',
          verified: true,
        });
      },
    },
    {
      name: 'Unverify Selected',
      shortcut: 'Ctrl + 2',
      action: () => {
        getBatchUpdateHandler(
          updateDataServiceMethod,
          [verified.field],
          false
        )({
          batch_action: 'batch unverified',
          verified: false,
        });
      },
    },
    {
      name: 'Update Revenue Category of Selected',
      shortcut: 'Ctrl + 3',
      action: () => {
        dispatch(revenueCategoryBatchActionClicked());
      },
    },
    {
      name: 'Add Code Tags to Selected',
      shortcut: 'Ctrl + 4',
      action: () => {
        dispatch(addCodeTagsBatchActionClicked());
      },
    },
    {
      name: 'Replace Code Tags of Selected',
      shortcut: 'Ctrl + 5',
      action: () => {
        dispatch(replaceCodeTagsBatchActionClicked());
      },
    },
    {
      name: 'Remove Code Tags of Selected',
      shortcut: 'Ctrl + 6',
      action: () => {
        dispatch(removeCodeTagsBatchActionClicked());
      },
    },
    {
      name: 'Update Paid Doses of Selected',
      shortcut: 'Ctrl + 7',
      action: () => {
        dispatch(updatePaidDosesBatchActionClicked());
      },
    },
    {
      name: 'Update Free Doses of Selected',
      shortcut: 'Ctrl + 8',
      action: () => {
        dispatch(updateFreeDosesBatchActionClicked());
      },
    },
    {
      name: 'Update Review Status of Selected',
      shortcut: 'Ctrl + 9',
      action: () => {
        dispatch(updateReviewStatusBatchActionClicked());
      },
    },
  ];

  if (jobType !== CLINIC_JOB_TYPE) {
    contextMenuItems.push({
      name: 'Update Verification Status of Selected',
      shortcut: 'Ctrl + 0',
      action: () => {
        dispatch(updateVerificationStatusBatchActionClicked());
      },
    });
  }

  return contextMenuItems;
};
export const onCellKeyDown = (
  setIsRequest,
  event,
  selectionInProgressRef,
  gridApi,
  setErrorAlert,
  allCodeTags,
  dispatch,
  getBatchUpdateHandler,
  updateDataServiceMethod,
  cellFocusedByKeyboardNavigationRef,
  changeMappedCodeCount,
  jobId
) => {
  cellFocusedByKeyboardNavigationRef.current =
    !event.shiftKey &&
    ARROWS_KEY_CODES.includes(event.keyCode) &&
    !isEditInProgress(gridApi);
  if (event.shiftKey && UP_DOWN_KEY_CODES.includes(event.keyCode)) {
    selectionInProgressRef.current = true;
  }

  if (DELETE_KEY_CODES.includes(event.keyCode) && !isEditInProgress(gridApi)) {
    deleteData(
      setIsRequest,
      gridApi,
      setErrorAlert,
      allCodeTags,
      updateDataServiceMethod,
      changeMappedCodeCount,
      jobId
    );
  }
  if (event.ctrlKey) {
    switch (event.key) {
      case '1':
        getBatchUpdateHandler(
          updateDataServiceMethod,
          [verified.field],
          false
        )({
          batch_action: 'batch verified',
          verified: true,
        });
        break;
      case '2':
        getBatchUpdateHandler(
          updateDataServiceMethod,
          [verified.field],
          false
        )({
          batch_action: 'batch unverified',
          verified: false,
        });
        break;
      case '3':
        dispatch(revenueCategoryBatchActionClicked());
        break;
      case '4':
        dispatch(addCodeTagsBatchActionClicked());
        break;
      case '5':
        dispatch(replaceCodeTagsBatchActionClicked());
        break;
      case '6':
        dispatch(removeCodeTagsBatchActionClicked());
        break;
      case '7':
        dispatch(updatePaidDosesBatchActionClicked());
        break;
      case '8':
        dispatch(updateFreeDosesBatchActionClicked());
        break;
      case '9':
        dispatch(updateReviewStatusBatchActionClicked());
        break;
      case '0':
        dispatch(updateVerificationStatusBatchActionClicked());
        break;
    }
  }
};

export const onRangeSelectionChanged = (event, selectionInProgressRef) => {
  //if drag is not in progress range selection changed should not do rows selection
  //for example when this is done using api (onCellMouseOver event)
  if ((event.started && event.finished) || !selectionInProgressRef.current) {
    return;
  } else {
    event.api.deselectAll();
    let cellRanges = event.api.getCellRanges();
    if (
      cellRanges.length > 0 &&
      cellRanges[0].startRow &&
      cellRanges[0].endRow
    ) {
      let rangeStartRow = Math.min(
        cellRanges[0].startRow.rowIndex,
        cellRanges[0].endRow.rowIndex
      );
      let rangeEndRow = Math.max(
        cellRanges[0].startRow.rowIndex,
        cellRanges[0].endRow.rowIndex
      );
      for (let rowIndex = rangeStartRow; rowIndex <= rangeEndRow; rowIndex++) {
        event.api.getDisplayedRowAtIndex(rowIndex).setSelected(true);
      }
    }
  }
};

export const onCellValueChanged = (params, updateCallback, setErrorAlert) => {
  //cellEditing events do not register copy/paste actions. So we have to handle those changes in this callback
  //we can't handle all non-fillHandle changes in this callback since params.source (for ex: if (params.source !== 'rangeService')) is undefined for manual cellEditing
  //if we remove fillOperation and try handling all changes here, fillHandle does not work
  if (
    UNDO_REDO_ACTIONS.includes(params.source) &&
    cellValueChanged(params.oldValue, params.newValue)
  ) {
    try {
      let column = params.column.colId;
      updateCallback(
        column,
        params.data,
        params.newValue,
        params.node,
        params.oldValue,
        true
      );
    } catch (error) {
      setErrorAlert({ error: error });
      params.node.setDataValue(params.column.colId, params.oldValue);
    }
  }
  if (params.source === 'paste') {
    if (!params.newValue && params.column.colId === revenue_category_id.field) {
      params.node.setDataValue(revenue_category_id.field, params.oldValue);
      return;
    }
    if (cellValueChanged(params.oldValue, params.newValue)) {
      try {
        let column = params.column.colId;
        updateCallback(
          column,
          params.data,
          params.newValue,
          params.node,
          params.oldValue
        );
      } catch (error) {
        setErrorAlert({ error: error });
        params.node.setDataValue(params.column.colId, params.oldValue);
      }
    }
  }
};

export const verifiedRenderer = (params) => {
  let value = [true, 'true'].includes(params.value) ? 'Yes' : 'No';
  let className = [true, 'true'].includes(params.value)
    ? 'verified-cell is-verified'
    : 'verified-cell';
  return (
    <div translate="no" className={className}>
      {value}
    </div>
  );
};

export const userRenderer = (users, userId) => {
  return (
    <UserPresentation
      label={getValueById(users, userId)}
      tooltip={getTooltipById(users, userId)}
    />
  );
};

export const noTranslateRenderer = (params) => {
  return <span translate="no">{params.value ? params.value : '\u2014'}</span>;
};

export const cellValueChanged = (oldValue, newValue) => {
  if (Array.isArray(oldValue) && Array.isArray(newValue)) {
    oldValue = oldValue.sort();
    newValue = newValue.sort();
  }
  return (oldValue?.toString() || '') !== (newValue?.toString() || '');
};

export const filterOutUnchangedCodes = (updateColumns, formParams, data) => {
  //Add Code Tags
  if (Object.prototype.hasOwnProperty.call(formParams, code_tags_ids.field)) {
    return !formParams[code_tags_ids.field].every((ct) =>
      data[code_tags_ids.field].includes(ct)
    );
  }
  //Replace Code Tags
  if (
    Object.prototype.hasOwnProperty.call(formParams, 'old_code_tag_id') &&
    Object.prototype.hasOwnProperty.call(formParams, 'new_code_tag_id')
  ) {
    if (
      !data[code_tags_ids.field].length ||
      formParams['old_code_tag_id'] === formParams['new_code_tag_id']
    ) {
      return false;
    }
    return data[code_tags_ids.field].includes(formParams['old_code_tag_id']);
  }
  //Remove Code Tags
  if (Object.prototype.hasOwnProperty.call(formParams, 'code_tag_id')) {
    if (!data[code_tags_ids.field].length) {
      return false;
    }
    return (
      formParams['code_tag_id'] === '' ||
      data[code_tags_ids.field].includes(formParams['code_tag_id'])
    );
  }

  return updateColumns.some(
    (column) =>
      Object.prototype.hasOwnProperty.call(formParams, column) &&
      cellValueChanged(data[column], formParams[column])
  );
};

export const removeBracketsAndNumbers = (string) => {
  return string.replace(/\[\d+\]/g, '');
};

export const parseNameFromStringWithId = (string) => {
  return regexParseNameAndId.exec(string)[1];
};

export const parseIdFromStringWithId = (string) => {
  let regexMatch = regexParseNameAndId.exec(string);
  return regexMatch[2] ? parseInt(regexMatch[2]) : null;
};
