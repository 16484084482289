import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  batchUpdateCodeTagsAdd,
  batchUpdateCodeTagsRemove,
  batchUpdateCodeTagsReplace,
  downloadCsv,
  getCodeMappings,
  getCodeMappingsCount,
  updateCodeMappings,
  updateTableRow,
} from '../../services/mappingDataServices';
import { createPortal } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import {
  allFilters,
  code_tags_ids,
  DEFAULT_PAGE_SIZE,
  DOWNLOAD_CODES_COUNT_LIMIT,
  free_doses,
  has_transactions,
  paid_doses,
  pms_code_vetsuccess_id,
  revenue_category_id,
  review_status,
  UNDO_REDO_ACTION,
  UNDO_REDO_EDIT_LIMIT,
  verification_pipeline_status_id,
  verified,
} from '../../constants/constants';
import {
  serverErrorInModal,
  modalClosed,
  confirmButtonClicked,
} from '../../features/modal/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { cancelNavigationPrompt } from '../../cancelNavigationPrompt';
import ConfirmationModal from '../common/ConfirmationModal';
import {
  collapseInactiveFilters,
  getActiveFiltersStatus,
  getSortModel,
  isMandatoryFilterPresent,
  mapFilterParams,
  resetMappingsFilters,
} from '../../helpers/tableFiltersHelper';
import {
  defaultColumnDefinition,
  getColumnDefinitions,
} from './columnDefinitions';
import {
  advanceToNextRow,
  clearLongRequest,
  fillOperation,
  filterOutUnchangedCodes,
  formatRequest,
  getContextMenuItems,
  guessRowCount,
  isDosesColumn,
  isPseudoeditableColumn,
  onCellEditingStopped,
  onCellKeyDown,
  onCellMouseOver,
  onCellValueChanged,
  onRangeSelectionChanged,
  paginationNumberFormatter,
  saveCSVFile,
  sidebarDef,
} from '../../helpers/tableHelper';
import PageSize from './PageSize';
import {
  renderMappingsPageFilterActions,
  renderOverlayMessage,
} from './tableRenders';
import BatchActionsModals from './BatchActionsModals';
import ActiveFilters from './filters/ActiveFilters';
import MappingDropdown from '../mapping/MappingDropdown';
import { updateTableData } from '../../services/tableServices';
import eyeIcon from '../../assets/icons/eye.svg';
import { UPDATE_WAITING_TIME_LIMIT } from '../../constants/jobConstants';
import Loader from '../common/Loader';
import UndoActions from './UndoActions';
import { getUndoRedoShortcut } from '../../helpers/commonHelper';
import { getMappableElements } from '../../helpers/selectHelper';
import { ErrorContext } from '../../ErrorContext';
import { parseError } from '../../helpers/errorHelper';
import { isSuperAdmin } from '../../helpers/userHelper';
import CreateJobModal from '../job/CreateJobModal';
import CustomJobForm from '../job/forms/CustomJobForm';

export default function Table({ readOnly, gridOptions }) {
  const { setErrorAlert } = useContext(ErrorContext);
  const {
    allCodeTags,
    allRevenueCategories,
    users,
    practices,
    groups,
    practiceGroupRelationships,
  } = useSelector((state) => state.tableData);

  const { pipelineStatusesForSelect } = useSelector(
    (state) => state.pipelineStatus
  );
  const { userInfo } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isTableReady, setIsTableReady] = useState(false);
  const gridRef = useRef();
  const tableRef = useRef(null);
  const appliedFiltersParamsRef = useRef(null);
  const [filterActive, setFilterActive] = useState();
  const [isRequest, setIsRequest] = useState(false);
  const [createCustomJobOpen, setCreateCustomJobOpen] = useState(false);
  const [isUndoAvailable, setUndoAvailable] = useState(false);
  const [isRedoAvailable, setRedoAvailable] = useState(false);
  //used for deciding if download button will be disabled or not and for overlay message
  const [rowCount, setRowCount] = useState(null);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [isShowAllFilters, setIsShowAllFilters] = useState(false);
  const [activeFiltersStatus, setActiveFiltersStatus] = useState([]);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(true);
  const [showLeavePageConfirmationDialog, setShowLeavePageConfirmationDialog] =
    useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    cancelNavigationPrompt(showLeavePageConfirmationDialog);
  const selectionInProgressRef = useRef(false);
  const previousMouseOverColumnRef = useRef(null);
  const cellFocusedByKeyboardNavigationRef = useRef(false);
  const undoActionShortcut = getUndoRedoShortcut(UNDO_REDO_ACTION.undo);
  const redoActionShortcut = getUndoRedoShortcut(UNDO_REDO_ACTION.redo);
  const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [isClearSortVisible, setIsClearSortVisible] = useState(false);

  const alertUser = useCallback((e) => {
    e.preventDefault();
    e.returnValue = '';
  }, []);

  let paginationAttributes = null;
  const fillHandleDataRef = useRef({
    requests: [],
    rows: [],
  });

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser, { once: true });
  }, []);

  // Using gridOptions for testing purposes.
  // Note: Showing and hiding of columns only works if done directly via the grid api.
  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridOptions.api = gridRef.current?.api;
    }
  }, [gridRef.current?.api]);

  useEffect(() => {
    gridRef.current.api?.setGridOption('paginationPageSize', currentPageSize);
    gridRef.current.api?.setGridOption('cacheBlockSize', currentPageSize);
    gridRef.current.api?.deselectAll();
  }, [currentPageSize]);

  const columnDefs = useMemo(
    () =>
      getColumnDefinitions({
        tableRef,
        practices,
        allRevenueCategories,
        allCodeTags,
        pipelineStatusesForSelect,
        users,
        groups,
        practiceGroupRelationships,
        readOnly,
      }),
    []
  );

  const onFilterModified = useCallback(() => {
    setIsShowAllFilters(
      isMandatoryFilterPresent(gridRef.current.api.getFilterModel())
    );
  }, []);

  const defaultColDef = useMemo(
    () => defaultColumnDefinition(readOnly, onFilterModified),
    []
  );

  const defaultFilterModel = useMemo(() => {
    return {
      [verified.field]: { value: 'No', type: 'radio', label: 'Verified' },
      [has_transactions.field]: {
        value: 'Yes',
        type: 'radio',
        label: 'Has Transactions',
      },
    };
  }, []);

  function setPaginationAttributes(mappings, sortModel) {
    paginationAttributes =
      mappings.length > 0
        ? {
            pms_code_vetsuccess_id:
              mappings[mappings.length - 1].pms_code_vetsuccess_id,
            ...(sortModel &&
              sortModel.length && {
                [sortModel[0].colId]:
                  mappings[mappings.length - 1][sortModel[0].colId],
              }),
          }
        : null;
  }

  useEffect(() => {
    if (gridRef.current.api) {
      const createDatasource = {
        getRows(params) {
          let onFirstPage =
            params.request.startRow === 0 &&
            params.request.endRow === params.api.paginationGetPageSize();
          //because of select all (since new page is not true for all paginationChanged events)
          gridRef.current.api.deselectAll();
          gridRef.current.api.clearRangeSelection();
          if (
            isMandatoryFilterPresent(gridRef.current.api.getFilterModel()) &&
            onFirstPage
          ) {
            setIsRequest(true);
            let promises = [
              getCodeMappings(
                mapFilterParams(
                  gridRef.current.api,
                  params.request.sortModel[0],
                  true,
                  paginationAttributes,
                  params.request.startRow
                )
              ).then((mappings) => {
                //when data arrives set data in table (don't wait for count)
                params.success({ rowData: mappings });
                setRowCount(
                  guessRowCount(gridRef.current.api, mappings.length)
                );
                setPaginationAttributes(mappings, params.request.sortModel);
                return mappings;
              }),
              getCodeMappingsCount(mapFilterParams(gridRef.current.api)),
            ];
            Promise.allSettled(promises)
              .then((responses) => {
                let countResponse = responses[1];
                let mappingsResponse = responses[0];
                if (mappingsResponse.status === 'rejected') {
                  setErrorAlert({ error: mappingsResponse.reason });
                  // if we do params.fail(); we will have ERR displayed in table and
                  // 1 to 50 of more in pagination which is misleading
                  params.success({ rowData: [], rowCount: 0 });
                  setRowCount(-1);
                } else if (
                  mappingsResponse.status === 'fulfilled' &&
                  countResponse.status === 'rejected'
                ) {
                  let mappings = mappingsResponse.value;
                  params.success({ rowData: mappings });
                  setPaginationAttributes(mappings, params.request.sortModel);
                  let guessedCount = guessRowCount(
                    gridRef.current.api,
                    gridRef.current.api.getDisplayedRowCount()
                  );
                  setRowCount(guessedCount);
                  if (guessedCount > 0)
                    setShowLeavePageConfirmationDialog(true);
                } else if (
                  mappingsResponse.status === 'fulfilled' &&
                  countResponse.status === 'fulfilled'
                ) {
                  let mappings = mappingsResponse.value;
                  let count = countResponse.value;
                  params.success({ rowData: mappings, rowCount: count });
                  setPaginationAttributes(mappings, params.request.sortModel);
                  setRowCount(count);
                  if (count > 0) setShowLeavePageConfirmationDialog(true);
                }
                setIsRequest(false);
              })
              .catch((err) => {
                setRowCount(-1);
                setErrorAlert({ error: err });
                params.success({ rowData: [], rowCount: 0 });
                setIsRequest(false);
              });
            setActiveFiltersStatus(
              getActiveFiltersStatus(gridRef.current.api.getFilterModel())
            );
          } else if (
            isMandatoryFilterPresent(gridRef.current.api.getFilterModel()) &&
            !onFirstPage
          ) {
            //we will not update row count if user is fetching next page
            setIsRequest(true);
            getCodeMappings(
              mapFilterParams(
                gridRef.current.api,
                params.request.sortModel[0],
                true,
                paginationAttributes,
                params.request.startRow
              )
            )
              .then((mappings) => {
                //when data arrives set data in table (don't wait for count)
                setIsRequest(false);
                params.success({ rowData: mappings });
                setPaginationAttributes(mappings, params.request.sortModel);
              })
              .catch((err) => {
                setRowCount(-1);
                setErrorAlert({ error: err });
                params.success({ rowData: [], rowCount: 0 });
                setIsRequest(false);
              });
          } else {
            setRowCount(-1);
            params.success({ rowData: [], rowCount: 0 });
            setActiveFiltersStatus(getActiveFiltersStatus([]));
          }
        },
      };
      gridRef.current.api.setGridOption(
        'serverSideDatasource',
        createDatasource
      );
    }
  }, [gridRef.current?.api]);

  const onGridReady = useCallback((e) => {
    e.api.resetColumnState();

    const filtersToolPanel = e.api.getToolPanelInstance('filters');
    const filterLayout = Array.from(allFilters, (f) => {
      return { field: f };
    });
    filtersToolPanel.setFilterLayout(filterLayout);
    filtersToolPanel.expandFilters(Object.keys(defaultFilterModel));

    setIsTableReady(true);
  }, []);

  const onGridSizeChanged = useCallback((e) => {
    e.api.refreshHeader();
  }, []);

  const getRowId = useCallback((params) => {
    return params.data[pms_code_vetsuccess_id.field];
  }, []);

  const onSortChanged = useCallback((event) => {
    gridRef.current.api.deselectAll();
    event.api.paginationGoToFirstPage();
    setIsClearSortVisible(event.api.getColumnState().some((c) => c.sort));
  }, []);

  const onSortReset = useCallback(() => {
    gridRef.current.api.applyColumnState({
      defaultState: { sort: null },
    });
  }, [gridRef.current?.api]);

  const onFilterChanged = useCallback((e) => {
    setFilterActive(e.api.isAnyFilterPresent());
    appliedFiltersParamsRef.current = mapFilterParams(
      gridRef.current.api,
      getSortModel(gridRef.current.api.getColumnState())
    );
    collapseInactiveFilters(e.api);
    onFilterModified();
  }, []);

  function updateUndoRedoState(params) {
    setUndoAvailable(params.api.getCurrentUndoSize() > 0);
    setRedoAvailable(params.api.getCurrentRedoSize() > 0);
  }

  const onCellValueChangedCallback = useCallback((params) => {
    onCellValueChanged(params, updateCallback, setErrorAlert);

    setTimeout(function () {
      //wait just in case to handle model update issues and undo/redo stack changes
      updateUndoRedoState(params);
    }, 1000);
  }, []);

  const onModelUpdated = useCallback((params) => {
    //this is triggered on row height changed - we need to update undo/redo size when this happens since undo/redo stack is cleared by ag grid
    return !readOnly ? updateUndoRedoState(params) : null;
  }, []);

  const getContextMenuItemsCallback = useCallback((event) => {
    return !readOnly
      ? getContextMenuItems(
          event,
          dispatch,
          getBatchUpdateHandler,
          updateCodeMappings
        )
      : null;
  }, []);

  const onCellContextMenu = useCallback((event) => {
    event.node.setSelected(true);
  }, []);

  const onRangeSelectionChangedCallback = useCallback((event) => {
    onRangeSelectionChanged(event, selectionInProgressRef);
  }, []);

  const onCellEditingStoppedCallback = useCallback((event) => {
    onCellEditingStopped(event, updateCallback, setErrorAlert);
  }, []);

  const onCellKeyDownCallback = useCallback((e) => {
    !readOnly &&
      onCellKeyDown(
        setIsRequest,
        e.event,
        selectionInProgressRef,
        e.api,
        setErrorAlert,
        allCodeTags,
        dispatch,
        getBatchUpdateHandler,
        updateCodeMappings,
        cellFocusedByKeyboardNavigationRef
      );
  }, []);

  const sideBar = useMemo(() => {
    return sidebarDef;
  }, []);

  const createFilterStatusPortal = () => {
    if (isTableReady) {
      return createPortal(
        <ActiveFilters status={activeFiltersStatus} />,
        document.querySelector('.ag-tool-panel-wrapper')
      );
    }
  };

  const createFilterStatus = useMemo(
    () => createFilterStatusPortal(),
    [isTableReady, activeFiltersStatus]
  );

  const downloadCSVFile = () => {
    setDownloadInProgress(true);
    downloadCsv(appliedFiltersParamsRef.current)
      .then((response) => {
        setDownloadInProgress(false);
        saveCSVFile(response);
      })
      .catch((error) => {
        setDownloadInProgress(false);
        setErrorAlert({ error: error });
      });
  };

  const onDragStarted = useCallback(() => {
    selectionInProgressRef.current = true;
  }, []);

  const onDragStopped = useCallback(() => {
    selectionInProgressRef.current = false;
  }, []);

  const onDialogCancel = useCallback(() => {
    dispatch(modalClosed());
  }, []);

  function getBatchUpdateHandler(updateFunction, updateColumns, modal = true) {
    return (formParams) => {
      if (modal) {
        // if we dispatch this for batch actions without modal (verified)
        // modalClosed will not be triggered at all if action results in error
        // and this will result in next modal having buttons disabled
        dispatch(confirmButtonClicked());
      }
      // We don't filter out codes for code_tags batch actions, since mapped_at is not being changed in the job mappings table.
      // However, this isn't the case for the mappings table, for some reason.
      let selectedPmsIds = gridRef.current.api
        .getSelectedNodes()
        .filter((node) =>
          filterOutUnchangedCodes(updateColumns, formParams, node.data)
        )
        .map((node) => node.data[pms_code_vetsuccess_id.field]);
      if (selectedPmsIds.length) {
        let timerId = setTimeout(function () {
          setIsRequest(true);
          gridRef.current.api.setGridOption('loading', true);
        }, UPDATE_WAITING_TIME_LIMIT);
        let updateParams = {
          pms_code_vetsuccess_id: selectedPmsIds,
        };
        updateFunction({ ...updateParams, ...formParams })
          .then((updatedResources) => {
            updateTableData(
              gridRef.current.api.getSelectedNodes(),
              updatedResources,
              allCodeTags,
              updateColumns
            );
            dispatch(modalClosed());
          })
          .catch((error) => {
            modal
              ? dispatch(serverErrorInModal(parseError(error).description))
              : setErrorAlert({ error: error });
          })
          .finally(() => {
            clearLongRequest(timerId, setIsRequest, gridRef.current.api);
          });
      } else {
        dispatch(modalClosed());
      }
    };
  }

  const updateCallback = (
    column,
    data,
    newValue,
    node,
    oldValue,
    undoAction
  ) => {
    //maybe someone used fill handle with unmappable ct/rc so use all
    if (!isPseudoeditableColumn(column)) {
      let formattedRequest = formatRequest(
        column,
        data,
        newValue,
        getMappableElements(allCodeTags),
        allRevenueCategories,
        undoAction
      );
      if (formattedRequest) {
        let timerId = setTimeout(function () {
          setIsRequest(true);
          gridRef.current.api.setGridOption('loading', true);
        }, UPDATE_WAITING_TIME_LIMIT);
        updateTableRow(formattedRequest)
          .then((response) => {
            updateTableData([node], response.data.resources, allCodeTags, [
              column,
            ]);
            if (isDosesColumn(column) && !undoAction) {
              advanceToNextRow(gridRef.current.api, node.rowIndex, column);
            }
          })
          .catch((error) => {
            setErrorAlert({ error: error });
            node.setDataValue(column, oldValue);
          })
          .finally(() => {
            clearLongRequest(timerId, setIsRequest, gridRef.current.api);
          });
      }
    }
  };

  const onToolPanelVisibleChanged = useCallback((event) => {
    setIsFilterPanelOpen(event.api.isToolPanelShowing());
  }, []);

  const onCellMouseOverHandler = useCallback(
    (e) =>
      !readOnly &&
      onCellMouseOver(
        e,
        previousMouseOverColumnRef,
        cellFocusedByKeyboardNavigationRef
      ),
    []
  );

  const rowClassRules = useMemo(
    () => ({
      '!bg-row-red': (params) =>
        params.node?.data?.verification_pipeline_status_id,
    }),
    []
  );

  const addCodeTagsHandler = useMemo(
    () => getBatchUpdateHandler(batchUpdateCodeTagsAdd, [code_tags_ids.field]),
    []
  );

  const removeCodeTagsHandler = useMemo(
    () =>
      getBatchUpdateHandler(batchUpdateCodeTagsRemove, [code_tags_ids.field]),
    []
  );

  const replaceCodeTagsHandler = useMemo(
    () =>
      getBatchUpdateHandler(batchUpdateCodeTagsReplace, [code_tags_ids.field]),
    []
  );

  const updateRevenueCategoryHandler = useMemo(
    () =>
      getBatchUpdateHandler(updateCodeMappings, [
        revenue_category_id.field,
        verified.field,
      ]),
    []
  );

  const updateReviewStatusHandler = useMemo(
    () => getBatchUpdateHandler(updateCodeMappings, [review_status.field]),
    []
  );

  const updateFreeDosesHandler = useMemo(
    () => getBatchUpdateHandler(updateCodeMappings, [free_doses.field]),
    []
  );

  const updatePaidDosesHandler = useMemo(
    () => getBatchUpdateHandler(updateCodeMappings, [paid_doses.field]),
    []
  );

  const updateVerificationStatusHandler = useMemo(
    () =>
      getBatchUpdateHandler(updateCodeMappings, [
        verification_pipeline_status_id.field,
      ]),
    []
  );

  const createFilterActionsPortal = () => {
    if (isTableReady && document.querySelector('.ag-tool-panel-wrapper')) {
      return createPortal(
        renderMappingsPageFilterActions(
          gridRef.current.api,
          isRequest,
          false,
          resetMappingsFilters,
          setShowLeavePageConfirmationDialog,
          defaultFilterModel,
          isShowAllFilters,
          onSortReset,
          isClearSortVisible
        ),
        document.querySelector('.ag-tool-panel-wrapper')
      );
    }
  };

  const createFilterActions = useMemo(
    () => createFilterActionsPortal(),
    [
      gridRef.current?.api,
      gridRef.current?.api.getFilterModel(),
      isRequest,
      isShowAllFilters,
    ]
  );

  const createDownloadCSVDropdownItem = useMemo(() => {
    const isDisabled =
      rowCount <= 0 ||
      rowCount > DOWNLOAD_CODES_COUNT_LIMIT ||
      downloadInProgress ||
      isRequest;
    return {
      label: 'Download CSV',
      action: downloadCSVFile,
      disabled: isDisabled,
    };
  }, [rowCount, downloadInProgress, isRequest]);

  const createCustomJobDropdownItem = useMemo(() => {
    const conditions = [
      { condition: isRequest, reason: 'A request is in progress.' },
      {
        condition: !filterActive,
        reason: 'Filters are not applied.',
      },
      {
        condition: !isShowAllFilters && (!rowCount || rowCount < 0),
        reason: 'At least one mandatory filter has to be selected',
      },
      { condition: !rowCount, reason: 'No codes available.' },
      {
        condition: rowCount <= 0,
        reason: 'Code count must be greater than zero.',
      },
      {
        condition: rowCount > 20000,
        reason: 'Code count exceeds the limit of 20,000.',
      },
    ];

    const createCustomJobDisabledReason =
      conditions.find(({ condition }) => condition)?.reason || null;

    return [
      {
        label: 'Create Custom Job',
        action: () => setCreateCustomJobOpen(true),
        disabled: createCustomJobDisabledReason !== null,
        disabledReason: createCustomJobDisabledReason,
      },
    ];
  }, [isRequest, filterActive, isShowAllFilters, rowCount]);

  const dropdownItems = useMemo(
    () => [
      createDownloadCSVDropdownItem,
      ...(isSuperAdmin(userInfo) ? createCustomJobDropdownItem : []),
    ],
    [userInfo, createDownloadCSVDropdownItem, createCustomJobDropdownItem]
  );

  const createActionsDropdownPortal = () => {
    if (
      isTableReady &&
      document.querySelector('#actions-dropdown-placeholder')
    ) {
      return createPortal(
        <>
          <MappingDropdown items={dropdownItems} />
          {readOnly && (
            <div className="flex items-center text-sm">
              <div className="group relative">
                <img className="ml-4" src={eyeIcon} alt="eye-icon" />
                <div className="status-tooltip">
                  <div className="status-tooltip-arrow" />
                  <div className="font-semibold">Read-only mode</div>
                </div>
              </div>
            </div>
          )}
          {downloadInProgress && (
            <div className="flex items-center ml-4">
              <div className="w-6 h-6 group relative">
                <Loader tooltipMessage="Downloading CSV" />
                <div className="status-tooltip">
                  <div className="font-semibold">Downloading CSV</div>
                </div>
              </div>
            </div>
          )}
        </>,

        document.querySelector('#actions-dropdown-placeholder')
      );
    }
  };

  const createActionsDropdown = useMemo(
    () => createActionsDropdownPortal(),
    [
      gridRef.current?.api,
      isTableReady,
      downloadInProgress,
      userInfo,
      isRequest,
      filterActive,
      isShowAllFilters,
      rowCount,
    ]
  );

  const createUndoActionsPortal = () => {
    if (
      !readOnly &&
      isTableReady &&
      document.querySelector('#undo-section-placeholder')
    ) {
      return createPortal(
        <UndoActions
          undoAvailable={isUndoAvailable}
          redoAvailable={isRedoAvailable}
          undoAction={() => gridRef.current?.api.undoCellEditing()}
          redoAction={() => gridRef.current?.api.redoCellEditing()}
          undoActionShortcut={undoActionShortcut}
          redoActionShorcut={redoActionShortcut}
        />,
        document.querySelector('#undo-section-placeholder')
      );
    }
  };

  const createUndoActions = useMemo(
    () => createUndoActionsPortal(),
    [isTableReady, isUndoAvailable, isRedoAvailable, gridRef.current?.api]
  );

  const initialState = {
    filter: {
      filterModel: defaultFilterModel,
    },
  };

  return (
    <>
      <div
        ref={tableRef}
        className={`ag-theme-alpine${
          isShowAllFilters ? '' : ' disable-secondary-filters'
        }`}
        style={{ height: '100%', width: '100%' }}
      >
        <AgGridReact
          ref={gridRef}
          gridOptions={gridOptions}
          rowModelType={'serverSide'}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          initialState={initialState}
          onGridReady={onGridReady}
          onGridSizeChanged={onGridSizeChanged}
          pagination={true}
          paginationPageSize={currentPageSize}
          paginationPageSizeSelector={false}
          paginationNumberFormatter={paginationNumberFormatter}
          cacheBlockSize={50}
          rowSelection={'multiple'}
          rowMultiSelectWithClick={false}
          tooltipShowDelay={500}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          getContextMenuItems={getContextMenuItemsCallback}
          onCellContextMenu={onCellContextMenu}
          onCellKeyDown={onCellKeyDownCallback}
          getRowId={getRowId}
          rowClassRules={rowClassRules}
          headerHeight={45}
          rowHeight={27}
          onCellEditingStopped={onCellEditingStoppedCallback}
          onRangeSelectionChanged={onRangeSelectionChangedCallback}
          enableRangeSelection
          undoRedoCellEditing={!readOnly}
          undoRedoCellEditingLimit={readOnly ? 0 : UNDO_REDO_EDIT_LIMIT}
          onModelUpdated={onModelUpdated}
          onDragStopped={onDragStopped}
          onDragStarted={onDragStarted}
          suppressMultiRangeSelection
          enableFillHandle
          fillHandleDirection="y"
          fillOperation={(params) =>
            fillOperation(
              setIsRequest,
              params,
              fillHandleDataRef,
              getMappableElements(allCodeTags),
              allRevenueCategories,
              updateCodeMappings,
              setErrorAlert
            )
          }
          stopEditingWhenCellsLoseFocus={true}
          overlayLoadingTemplate={
            '<div aria-live="polite" aria-atomic="true" class="loader" aria-label="loading"></div>'
          }
          sideBar={sideBar}
          onCellMouseOver={onCellMouseOverHandler}
          onCellValueChanged={onCellValueChangedCallback}
          onToolPanelVisibleChanged={onToolPanelVisibleChanged}
          rowBuffer={15}
          suppressMultiSort
          columnMenu={'legacy'}
        ></AgGridReact>
        <PageSize
          currentPageSize={currentPageSize}
          setPageSize={setCurrentPageSize}
        />
        {createActionsDropdown}
        {createFilterActions}
        {createFilterStatus}
        {createUndoActions}
        {isTableReady &&
          renderOverlayMessage({
            rowCount,
            filterActive,
            isShowAllFilters,
          })}
      </div>
      <div>
        <BatchActionsModals
          onDialogCancel={onDialogCancel}
          filtersPanelOpen={isFilterPanelOpen}
          addCodeTagsHandler={addCodeTagsHandler}
          removeCodeTagsHandler={removeCodeTagsHandler}
          replaceCodeTagsHandler={replaceCodeTagsHandler}
          updateRevenueCategoryHandler={updateRevenueCategoryHandler}
          updateReviewStatusHandler={updateReviewStatusHandler}
          updateFreeDosesHandler={updateFreeDosesHandler}
          updatePaidDosesHandler={updatePaidDosesHandler}
          updateVerificationStatusHandler={updateVerificationStatusHandler}
          availableCodeTags={allCodeTags}
        />
        <ConfirmationModal
          title="You're about to leave this page"
          isOpen={showPrompt}
          onButtonClick={confirmNavigation}
          onClose={cancelNavigation}
          buttonText="Yes, leave"
          text="Applied filters will get reset. Are you sure you want to leave?"
        />
        <CreateJobModal
          openState={createCustomJobOpen}
          onClose={() => setCreateCustomJobOpen(false)}
          title="Create Custom Job From Filters"
          filterJobCreation={true}
          customJobForm={
            <CustomJobForm
              onCancel={() => setCreateCustomJobOpen(false)}
              filterParams={appliedFiltersParamsRef.current}
              onJobStatusFailed={setErrorAlert}
            />
          }
        />
      </div>
    </>
  );
}
