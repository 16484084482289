import { useEffect, useState, useContext } from 'react';
import Table from '../table/Table';
import { useSelector, useDispatch } from 'react-redux';
import {
  loadCodeTags,
  loadGroups,
  loadPractices,
  loadRevenueCategories,
  loadUsers,
} from '../../features/table/tableActions';
import { loadPipelineStatuses } from '../../features/pipeline-status/pipelineStatusActions';
import Loader from '../common/Loader';
import { isOnboardingUser, isVSOTeamUser } from '../../helpers/userHelper';
import { ErrorContext } from '../../ErrorContext.jsx';
import { refreshPage } from '../../helpers/commonHelper';

export default function Mappings() {
  const { setErrorAlert } = useContext(ErrorContext);
  const [isLoaderVisible, setLoaderVisible] = useState(true);
  const { userInfo } = useSelector((state) => state.user);
  const {
    allCodeTags,
    allRevenueCategories,
    users,
    practices,
    preLoadingError,
    groups,
    practiceGroupRelationships,
  } = useSelector((state) => state.tableData);
  const { pipelineStatuses, loadingPipelineStatusesError } = useSelector(
    (state) => state.pipelineStatus
  );
  const dispatch = useDispatch();

  // we needed to make sure we have code tags and revenue categories loaded before ag grid is rendered, because of
  // fill operation grid option (which is dependant on code tags and categories) and stale fn closures
  // from their doc:
  // Note the if using Grid Options, the grid will not react to property changes.
  // For example gridOptions.rowData will only get used once when the grid is initialised, not if you change
  // gridOptions.rowData after the grid is initialised.
  // For this reason, while using React, it's best only use Grid Options for properties that do not change.

  useEffect(() => {
    if (!allCodeTags) {
      dispatch(loadCodeTags());
    }
  }, [allCodeTags]);

  useEffect(() => {
    if (!allRevenueCategories) {
      dispatch(loadRevenueCategories());
    }
  }, [allRevenueCategories]);

  useEffect(() => {
    if (!practices) {
      dispatch(loadPractices());
    }
  }, [practices]);

  useEffect(() => {
    if (!users) {
      dispatch(loadUsers());
    }
  }, [users]);

  useEffect(() => {
    if (!pipelineStatuses) dispatch(loadPipelineStatuses());
  }, [pipelineStatuses]);

  useEffect(() => {
    if (!groups) {
      dispatch(loadGroups());
    }
  }, [groups]);

  useEffect(() => {
    if (preLoadingError || loadingPipelineStatusesError) {
      setErrorAlert({
        error: preLoadingError || loadingPipelineStatusesError,
        buttonText: 'Try reloading',
        onButtonClick: refreshPage,
        closingButton: true,
      });
    }
  }, [preLoadingError, loadingPipelineStatusesError]);

  useEffect(() => {
    if (
      allCodeTags &&
      allRevenueCategories &&
      users &&
      practices &&
      pipelineStatuses &&
      groups &&
      practiceGroupRelationships
    ) {
      setLoaderVisible(false);
    }
  }, [
    allRevenueCategories,
    allCodeTags,
    users,
    practices,
    pipelineStatuses,
    groups,
    practiceGroupRelationships,
  ]);

  return (
    <>
      {isLoaderVisible && !preLoadingError && !loadingPipelineStatusesError && (
        <Loader />
      )}
      {!isLoaderVisible && !preLoadingError && !loadingPipelineStatusesError && (
        <div id="mappings" className="flex flex-col h-full flex-grow-1">
          <div
            className="flex mx-4 mb-4 flex-grow relative overflow-hidden"
            id="tableContainer"
          >
            <div id="mappingTable" className="w-full h-full">
              <Table
                readOnly={isVSOTeamUser(userInfo) || isOnboardingUser(userInfo)}
                gridOptions={{}}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
