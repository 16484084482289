import { useCallback, useState } from 'react';
import { useGridFilter } from 'ag-grid-react';
import { ResetFilterButton } from './ResetFilterButton';
import { applyFiltersOnKeyPress } from '../../../helpers/tableFiltersHelper.js';

export default ({
  api,
  colDef,
  getValue,
  model,
  onModelChange,
  onModelModify,
  clientSide,
  options,
}) => {
  const [checkboxFocused, setCheckboxFocused] = useState(null);

  const setFilterValues = useCallback((filterValues) => {
    const newModel = filterValues?.length
      ? {
          values: filterValues,
          type: 'checkbox',
          label: colDef.headerName,
        }
      : null;

    if (clientSide) {
      onModelChange(newModel);
    } else {
      // We are using setColumnFilterModel for server side table, to avoid instant filter apply caused by onModelChange
      api.setColumnFilterModel(colDef.field, newModel);
      onModelModify();
    }
  }, []);

  if (clientSide) {
    const doesFilterPass = useCallback(
      ({ node }) => {
        const value = getValue(node);
        const filterValues = model?.values;
        return filterValues.some((filterValue) => filterValue.value === value);
      },
      [model?.values]
    );

    // Register filter callbacks with the grid, doesFilterPass is mandatory for client side row model type
    useGridFilter({ doesFilterPass });
  }

  const resetFilter = useCallback(() => {
    setFilterValues([]);
    setCheckboxFocused(null);
  }, []);

  const handleChange = (item) => {
    const filterValues = model?.values || [];
    const element = filterValues.find((v) => v.value === item.value);
    element
      ? setFilterValues(filterValues.filter((v) => v.value !== item.value))
      : setFilterValues([...filterValues, item]);
    setCheckboxFocused(item);
  };

  const Checkbox = ({ item }) => {
    return (
      <div className="flex-auto">
        <input
          autoFocus={item === checkboxFocused}
          type="checkbox"
          value={item}
          checked={model?.values.includes(item)}
          onChange={() => handleChange(item)}
        />
        <span className="ml-2">{item.label}</span>
      </div>
    );
  };

  return (
    <div
      id="table-checkbox-filter"
      onKeyDown={({ key }) => !clientSide && applyFiltersOnKeyPress(key, api)}
    >
      <ResetFilterButton onReset={resetFilter} />
      <div className="flex p-2.5">
        {options.map((item) => (
          <Checkbox key={item.value} item={item} />
        ))}
      </div>
    </div>
  );
};
