import { useCallback, useEffect, useState } from 'react';
import { useGridFilter } from 'ag-grid-react';
import Select from 'react-select';
import { ResetFilterButton } from './ResetFilterButton';
import { SelectInput } from './SelectInput';
import { applyFiltersOnKeyPress } from '../../../helpers/tableFiltersHelper.js';
import arrowDown from '../../../assets/icons/arrow_downsvg.svg';

const logicalOperators = [
  { value: 'and', label: 'AND' },
  { value: 'or', label: 'OR' },
];

export default ({
  api,
  colDef,
  getValue,
  model,
  onModelChange,
  onModelModify,
  clientSide,
  operators,
  maxTextLength,
  maxNumberOfFilters = 3,
  hasOr,
}) => {
  const defaultOperator = operators ? operators[0] : null;
  const [filterValues, setFilterValues] = useState(Array(maxNumberOfFilters));
  const [activeFilterNumber, setActiveFilterNumber] = useState(1);
  const [logicalOperator, setLogicalOperator] = useState(logicalOperators[0]);
  const [filterOperators, setFilterOperators] = useState(
    Array(maxNumberOfFilters).fill(defaultOperator)
  );

  useEffect(() => {
    setFilterValues((prevValues) => {
      let newValues = { ...prevValues };
      for (
        let filterIndex = activeFilterNumber;
        filterIndex < maxNumberOfFilters;
        filterIndex++
      ) {
        newValues[filterIndex] = null;
      }
      return newValues;
    });

    setFilterOperators((prevOperators) => {
      let newOperators = { ...prevOperators };
      for (
        let filterIndex = activeFilterNumber;
        filterIndex < maxNumberOfFilters;
        filterIndex++
      ) {
        newOperators[filterIndex] = defaultOperator;
      }
      return newOperators;
    });
  }, [activeFilterNumber]);

  useEffect(() => {
    for (let filterIndex = 0; filterIndex < maxNumberOfFilters; filterIndex++) {
      if (!filterValues[filterIndex]) {
        setActiveFilterNumber(filterIndex + 1);
        return;
      }
    }
  }, [filterValues]);

  // We are using setColumnFilterModel for server side table, to avoid instant filter apply caused by onModelChange
  useEffect(() => {
    const createModel = () => {
      if (!isFilterActive()) return undefined;

      const values = [];
      for (
        let filterIndex = 0;
        filterIndex < maxNumberOfFilters;
        filterIndex++
      ) {
        addFilter(
          values,
          filterValues[filterIndex],
          filterOperators[filterIndex]
        );
      }
      return {
        values: values,
        logicalOperator: logicalOperator,
        type: 'text',
        label: colDef.headerName,
      };
    };

    const newModel = createModel();

    if (clientSide) {
      onModelChange(newModel);
    } else {
      api.setColumnFilterModel(colDef.field, newModel);
      onModelModify();
    }
  }, [filterValues, filterOperators, logicalOperator]);

  // handle global Clear Filters
  useEffect(() => {
    if (model === null) {
      resetFilter();
    }
  }, [model]);

  if (clientSide) {
    const filterPass = (valueLowerCase, filterOperator, filterText) => {
      const filterTextLowerCase = filterText.toLowerCase();
      switch (filterOperator) {
        case 'contains':
          return valueLowerCase.indexOf(filterTextLowerCase) >= 0;
        case 'notContains':
          return valueLowerCase.indexOf(filterTextLowerCase) === -1;
        case 'startsWith':
          return valueLowerCase.indexOf(filterTextLowerCase) === 0;
        case 'equals':
          return valueLowerCase === filterTextLowerCase;
        case 'endsWith': {
          const index = valueLowerCase.lastIndexOf(filterTextLowerCase);
          return (
            index >= 0 &&
            index === valueLowerCase.length - filterTextLowerCase.length
          );
        }
        default:
          return false;
      }
    };

    const doesFilterPass = useCallback(
      ({ node }) => {
        const value = getValue(node);
        const filterValues = (model?.values || []).map((fv) => fv.value);
        const filterOperators = (model?.values || []).map((fv) => fv.operator);
        const logicalOperator = model?.logicalOperator;
        const valueLowerCase = value?.toString().toLowerCase() || '';
        const filterValuesArray = filterValues.filter(
          (value) => value !== null && value !== ''
        );

        if (hasOr && logicalOperator === logicalOperators[1]) {
          return filterValuesArray.some((filterValue, i) =>
            filterPass(valueLowerCase, filterOperators[i], filterValue)
          );
        }
        return filterValuesArray.every((filterValue, i) =>
          filterPass(valueLowerCase, filterOperators[i], filterValue)
        );
      },
      [model]
    );

    // Register filter callbacks with the grid, doesFilterPass is mandatory for client side row model type
    useGridFilter({ doesFilterPass });
  }

  const resetFilter = useCallback(() => {
    setFilterOperators(Array(maxNumberOfFilters).fill(defaultOperator));
    setFilterValues(Array(maxNumberOfFilters));
  }, []);

  const isFilterActive = () => {
    return filterValues[0] != null && filterValues[0] !== '';
  };

  const addFilter = (values, text, operator) => {
    if (text) {
      values.push({
        value: text,
        operator: operator.value,
        operatorLabel: operator.label.toLowerCase(),
      });
    }
  };

  const updateFilterValues = (value, index) => {
    let newFilterValues = { ...filterValues };
    newFilterValues[index] = value;
    setFilterValues(newFilterValues);
  };

  const updateFilterOperator = (operator, index) => {
    let newFilterOperators = { ...filterOperators };
    newFilterOperators[index] = operator;
    setFilterOperators(newFilterOperators);
  };

  const filters = [];
  filters.push(
    <div key={'filterTextOptionDiv0'}>
      <SelectInput
        index="0"
        options={colDef.filterParams.operators}
        selectValue={filterOperators[0]}
        onSelectChange={updateFilterOperator}
        inputValue={filterValues[0]}
        onInputChange={updateFilterValues}
        maxLength={maxTextLength}
      />
    </div>
  );
  for (let i = 1; i < activeFilterNumber; i++) {
    if (filterValues[i - 1]) {
      filters.push(
        <div key={`filterTextOptionDiv${i}`}>
          {hasOr && i === 1 ? (
            <Select
              options={logicalOperators}
              value={logicalOperator}
              onChange={setLogicalOperator}
              className="w-20 m-auto p-2.5"
              menuPosition="fixed"
              components={{
                DropdownIndicator: () => (
                  <img src={arrowDown} className="w-5 pr-1" />
                ),
                IndicatorSeparator: () => null,
              }}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  minHeight: '30px',
                }),
                valueContainer: (baseStyles) => ({
                  ...baseStyles,
                  padding: '2px 0 2px 6px',
                  textAlign: 'center',
                }),
                menuList: (baseStyles) => ({
                  ...baseStyles,
                  textAlign: 'center',
                }),
              }}
            />
          ) : (
            <div className="text-center">{logicalOperator.label}</div>
          )}
          <SelectInput
            index={i}
            options={colDef.filterParams.operators}
            selectValue={filterOperators[i]}
            onSelectChange={updateFilterOperator}
            inputValue={filterValues[i]}
            onInputChange={updateFilterValues}
            maxLength={maxTextLength}
          />
        </div>
      );
    }
  }

  return (
    <div
      id="table-text-filter"
      onKeyDown={({ key }) => !clientSide && applyFiltersOnKeyPress(key, api)}
    >
      <ResetFilterButton onReset={resetFilter} />
      {filters}
    </div>
  );
};
